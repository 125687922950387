import Button from 'components/basics/Button';
import { Select } from 'components/forms';
import { Attach, CaretLeft, Done } from 'components/icons';
import { FC, useState } from 'react';
import Tags from './tags';
import { Modal } from 'antd';
import { ConversationHeaderProps } from './type';
import { getAssigneeOptions } from './util';
import { MessageStatus } from 'lib';
import { Avatar } from 'components/basics/Avatar/Avatar';
import { useHistory } from 'react-router';
import { useStore } from 'hooks/useStore';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import { useAtomValue } from 'jotai';
import {
  buildFrontMessageStatus,
  hasCustomStatus,
  statusesOptionsWhenCustomStatusedEnabled,
} from 'utils/frontMessageStatus';
import { Icon } from '../../../../../components/basics';

export const SPConversationHeader: FC<ConversationHeaderProps> = ({
  message,
  updateStatus,
  updateAssignee,
  users,
  teamMembers,
  messages,
  backLink,
}) => {
  const store = useStore();
  const [, statuses] = useAtomValue(statusesAtomFamily(message.teamId));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const back = () => {
    history.push(backLink());
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isReadOnly = store.me.isReadOnly;

  const assigneeOptionsData = getAssigneeOptions(users, teamMembers);
  const assigneeOptions = assigneeOptionsData.map((option) => {
    if (option.user) {
      const label = `${option.isTeamMember ? '' : '(チーム外)'}${
        option.user.name
      }`;

      return { value: option.user.id, label };
    }

    return { value: null, label: '担当者未設定' };
  });

  const renderLabel = (value: string | null, label: string) => {
    if (!value) {
      return label;
    }

    const option = assigneeOptionsData.find((item) => value === item.user?.id);
    if (!option) {
      return label;
    }

    return (
      <div className="whitespace-pre">
        <span className="mr-1 text-sm">担当者</span>
        <Avatar
          user={option.user || undefined}
          size={24}
          style={{ marginTop: -3 }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="fixed inset-x-0 top-0 z-10 flex w-full items-center justify-between bg-sumi-50 px-3 pb-1 pt-10">
        <button
          onClick={back}
          className="flex cursor-pointer items-center gap-2 bg-transparent p-0"
        >
          <Icon icon={CaretLeft} />
          <span className="text-sm">一覧に戻る</span>
        </button>

        {hasCustomStatus(statuses) ? (
          <Select
            optionsId="sp_conversation_header_customStatuses"
            className={isReadOnly ? 'bg-sumi-100' : 'bg-white'}
            value={message.status}
            disabled={isReadOnly}
            onChange={(status) =>
              updateStatus(buildFrontMessageStatus(status, statuses))
            }
            options={statusesOptionsWhenCustomStatusedEnabled(statuses)}
          />
        ) : (
          <Button
            type="button"
            color="primary"
            variant="outlined"
            disabled={isReadOnly}
            className={`${
              message.status === MessageStatus.Processed
                ? 'border-solid bg-sea-500 text-white'
                : 'border-dashed bg-white'
            } flex h-8 w-8 items-center justify-center rounded-lg`}
            onClick={() =>
              updateStatus(
                buildFrontMessageStatus(
                  message.status === MessageStatus.Processed
                    ? MessageStatus.Unprocessed
                    : MessageStatus.Processed
                )
              )
            }
          >
            <Icon icon={Done} className="shrink-0" />
          </Button>
        )}
      </div>

      <div className="px-3 pt-10">
        <p className="mb-3 mt-8 text-lg font-bold">
          {Boolean(message.attachments.length > 0) && (
            <Attach className="mr-2 align-sub" />
          )}
          <span>{message.subject}</span>
        </p>
        <div className="flex items-center justify-between">
          <div className="cursor-pointer" onClick={showModal}>
            <Tags
              message={message}
              messages={messages || [message]}
              isReadOnly
              showLess
            />
          </div>

          <Select
            optionsId="sp_conversation_header"
            value={message.assignee ?? null}
            renderLabel={renderLabel}
            disabled={isReadOnly}
            onChange={(value) => updateAssignee(value ?? null)}
            options={assigneeOptions}
            className="border-0"
          />
        </div>

        <Modal
          visible={isModalOpen}
          footer={null}
          closable={false}
          className="fixed inset-0 z-50 !m-0 !max-w-full !rounded-none"
        >
          <div className="h-screen w-full">
            <button
              onClick={handleCancel}
              className="flex cursor-pointer items-center gap-2 bg-transparent p-0"
            >
              <Icon icon={CaretLeft} />
              <span className="text-sm">メールに戻る</span>
            </button>
            <p className="mt-5 text-sm">タグを設定</p>
            <div className="mt-3">
              <Tags message={message} messages={messages || [message]} />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
