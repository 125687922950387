import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ComponentProps, useMemo } from 'react';
import moment from 'moment/moment';
import { DeliveryStatus } from '../DeliveryStatus/DeliveryStatus';
import SimpleBar from 'simplebar-react';
import { ColumnSort } from '@tanstack/table-core/src/features/RowSorting';
import { Icon } from '../../../basics';
import { Delete } from '../../../icons';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import { TablePagination } from '../../../table/TablePagination/TablePagination';
import { range } from 'lodash';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';
import { Table } from '../../../table/Table/Table';

type Status = ComponentProps<typeof DeliveryStatus>['status'];

type Entry = {
  id: string;
  subject: string;
  status: Status;
  updatedAt: Date;
  sentAt: Date | null;
};

type Props = {
  data: Entry[];
  sorting: ColumnSort | null;
  onSortingChange: (sorting: ColumnSort | null) => void;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (direction: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onMessageDelete: (messageId: string) => Promise<void>;
  loading: boolean;
};

export const DeliveryMessageIndexTable = ({
  data,
  sorting,
  onSortingChange,
  pageSize,
  onPageSizeChange,
  onPageChange,
  hasPreviousPage,
  hasNextPage,
  onMessageDelete,
  loading,
}: Props) => {
  const showDialog = useConfirmDialog();
  const columns: ColumnDef<Entry>[] = useMemo(() => {
    return [
      {
        accessorKey: 'subject',
        cell: (info) => info.getValue(),
        header: '件名',
      },
      {
        accessorKey: 'status',
        enableSorting: false,
        cell: (info) => {
          const status = info.getValue() as Status;
          return <DeliveryStatus status={status} />;
        },
        header: 'ステータス',
      },
      {
        accessorKey: 'updatedAt',
        cell: (info) => {
          const value = info.getValue() as Date;
          return moment(value).format('YYYY年M月D日 HH:mm');
        },
        header: '最終更新日時',
      },
      {
        accessorKey: 'sentAt',
        cell: (info) => {
          const value = info.getValue() as Date | null;
          if (!value) {
            return '-';
          }
          return moment(value).format('YYYY年M月D日 HH:mm');
        },
        header: '送信日時',
      },
    ];
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting: sorting ? [sorting] : [],
    },
    onSortingChange: (updated) =>
      typeof updated === 'function'
        ? onSortingChange(updated(sorting ? [sorting] : []).at(0) ?? null)
        : onSortingChange(updated.at(0) ?? null),
  });

  return (
    <div className="flex flex-col">
      <SimpleBar className="w-full" autoHide={false}>
        <Table className="w-full max-w-full">
          <Table.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Table.Th
                      key={header.id}
                      colSpan={header.colSpan}
                      sortable={header.column.getCanSort()}
                      sortDir={header.column.getIsSorted() || null}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.Th>
                  );
                })}
                <Table.Th />
              </Table.Tr>
            ))}
          </Table.Thead>
          <Table.Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Tr
                  key={row.id}
                  to={getEntryPath(row.original)}
                  className="group"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Td
                        key={cell.id}
                        withAnchor={cell.column.id === 'subject'}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Td>
                    );
                  })}
                  <Table.Td className="w-10 p-0">
                    {row.original.status === 'draft' && (
                      <div className="flex items-start justify-center">
                        <Tooltip content="下書きを削除">
                          <button
                            type="button"
                            className="invisible m-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded bg-transparent p-0 text-red-500 hover:bg-red-500/10 group-hover:visible"
                            aria-label="下書きを削除"
                            onClick={() => {
                              showDialog({
                                title: '下書きを削除しますか？',
                                okText: '削除',
                                okType: 'danger',
                                onOk: () => onMessageDelete(row.original.id),
                              });
                            }}
                          >
                            <Icon icon={Delete} size={20} />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </SimpleBar>
      {!loading && data.length === 0 && (
        <div className="flex h-24 w-full items-center justify-center">
          <span className="select-none text-sumi-700">データがありません</span>
        </div>
      )}
      {loading && data.length === 0 && (
        <div className="flex flex-col">
          {range(6).map((i) => (
            <div key={i} className="flex h-9 items-end">
              <div className="h-6 w-full animate-pulse rounded bg-sumi-100" />
            </div>
          ))}
        </div>
      )}
      <div className="mt-4 flex w-full justify-end">
        <TablePagination
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          sizes={[25, 50, 100]}
          onPageChange={onPageChange}
          hasPrevious={hasPreviousPage}
          hasNext={hasNextPage}
        />
      </div>
    </div>
  );
};

const getEntryPath = (entry: Entry) => {
  return entry.status === 'draft'
    ? DELIVERY_PATHS.getEditPath(entry.id)
    : DELIVERY_PATHS.getStatsPath(entry.id);
};
