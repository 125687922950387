import { CompanyMemberDrawer } from './CompanyMemberDrawer';
import { useHistory, useParams } from 'react-router-dom';
import { ComponentProps, useCallback, useMemo } from 'react';
import { User } from 'lib';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../../atoms/firestore/user';
import { companyAtom, meAtom } from '../../../../atoms/auth';
import { Role } from 'lib';
import {
  deleteUserFunction,
  updateUserRoleFunction,
} from '../../../../functions';
import { useToast } from '../../../../hooks/useToast';

export const CompanyMemberDrawerWithLogic = () => {
  const { userId } = useParams<{ userId?: string }>();
  const users = useAtomValue(usersAtom);
  const found = useMemo(
    () => users.find((u) => u.id === userId),
    [users, userId]
  );

  if (!found) {
    return null;
  }
  return <Internal key={userId} user={found} />;
};

const Internal = ({ user }: { user: User }) => {
  const history = useHistory();
  const me = useAtomValue(meAtom);
  const company = useAtomValue(companyAtom);
  const { showToast } = useToast();
  const handleOpenChange = useCallback(
    () => history.push('/settings/company/members'),
    [history]
  );
  const handleUpdate: ComponentProps<typeof CompanyMemberDrawer>['onUpdate'] =
    useCallback(
      async (id, role) => {
        try {
          await updateUserRoleFunction({
            companyId: company.id,
            userId: id,
            role,
          });
          showToast('success', 'メンバーを更新しました');
        } catch (e) {
          console.error(e);
          showToast('error', 'メンバーの更新に失敗しました');
        }
      },
      [company.id]
    );
  const handleDelete: ComponentProps<typeof CompanyMemberDrawer>['onDelete'] =
    useCallback(
      async (id) => {
        try {
          await deleteUserFunction({
            companyId: company.id,
            userId: id,
          });
          history.replace('/settings/company/members');
          showToast('success', 'メンバーを削除しました');
        } catch (e) {
          console.error(e);
          showToast('error', 'メンバーの削除に失敗しました');
        }
      },
      [company.id]
    );
  return (
    <CompanyMemberDrawer
      open={true}
      onOpenChange={handleOpenChange}
      id={user.id}
      email={user.email}
      name={user.name}
      role={user.role as Role}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      readonly={!me.isAdmin || user.id === me.id}
    />
  );
};
