import { TeamMemberEntry } from './TeamMembers';
import { useState } from 'react';
import { MemberManageItem } from '../../../components/settings/misc/MemberManageItem/MemberManageItem';

type Props = {
  title: string;
  placeholder: string;
  members: TeamMemberEntry[];
  type: 'add' | 'remove';
  onAction: (id: string) => Promise<void>;
  readonly: boolean;
};

export const TeamMembersSection = ({
  title,
  members,
  placeholder,
  type,
  onAction,
  readonly,
}: Props) => {
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const handleClick = async (id: string) => {
    try {
      setProcessingIds([...processingIds, id]);
      await onAction(id);
    } catch (e) {
      //
    } finally {
      setProcessingIds(processingIds.filter((i) => i !== id));
    }
  };
  return (
    <div className="mb-8 flex flex-col gap-4">
      <h2 className="m-0 text-base font-bold">{title}</h2>
      <div className="flex w-[350px] flex-col gap-4 text-sm">
        {members.length ? (
          members.map((member) => {
            const maxJoinedTeams =
              type === 'add' && member.joinedTeamCount >= 10;
            return (
              <MemberManageItem key={member.id}>
                <MemberManageItem.Member
                  name={member.name}
                  email={member.email}
                  avatar={member.avatar}
                  isMe={member.isMe}
                />
                <MemberManageItem.Button
                  type={type}
                  id={member.id}
                  onAction={handleClick}
                  disabled={readonly || maxJoinedTeams}
                  disabledReason={
                    readonly
                      ? '管理者のみ変更可能です'
                      : '1ユーザーが所属できる最大のチーム数は10個までとなります'
                  }
                />
              </MemberManageItem>
            );
          })
        ) : (
          <div className="text-sumi-500">{placeholder}</div>
        )}
      </div>
    </div>
  );
};
