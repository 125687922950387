import { ComponentProps, forwardRef, memo, useMemo } from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';

export type AvatarProps = {
  name?: string;
  src?: string;
  baseColor?: string;
  user?: {
    name?: string;
    avatarURL?: string;
    iconBackgroundColor?: string;
    iconName?: string;
  };
  showNameOnHover?: boolean;
  size: number;
};

type Props = Omit<ComponentProps<'div'>, 'children'> & AvatarProps;

export const Avatar = memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        name,
        src,
        user,
        baseColor,
        showNameOnHover,
        size,
        className,
        style,
        ...props
      },
      ref
    ) => {
      const backgroundColor = useMemo(() => {
        if (user?.avatarURL) {
          return '#ffffff';
        }
        if (user?.iconBackgroundColor) {
          return user.iconBackgroundColor;
        }
        return baseColor ?? '#cccccc';
      }, [user, baseColor]);
      const shortName = useMemo(() => {
        const shortName = user?.iconName || name || '?';
        return shortName.trim().substring(0, 2).toLocaleUpperCase('ja');
      }, [user?.iconName, name]);
      const avatarSrc = useMemo(
        () => user?.avatarURL || src,
        [user?.avatarURL, src]
      );
      return (
        <Tooltip
          content={user?.name || name || '削除されたユーザー'}
          visible={showNameOnHover}
        >
          <div
            className={twMerge(
              'inline-flex select-none items-center justify-center overflow-hidden rounded-full leading-none text-white',
              className
            )}
            style={{
              width: size,
              minWidth: size,
              height: size,
              minHeight: size,
              fontSize: size > 32 ? size / 3 : 12,
              backgroundColor,
              ...(style ?? {}),
            }}
            {...props}
            ref={ref}
          >
            {avatarSrc ? (
              <img
                src={avatarSrc}
                alt=""
                width={size}
                height={size}
                className="block"
                draggable={false}
              />
            ) : (
              shortName
            )}
          </div>
        </Tooltip>
      );
    }
  )
);

Avatar.displayName = 'Avatar';
