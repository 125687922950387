import { ComponentProps } from 'react';
import { SettingPageContainer } from '../common/SettingPageContainer/SettingPageContainer';
import { TeamMembersSection } from './TeamMembersSection';
import { MemberManageItem } from '../../../components/settings/misc/MemberManageItem/MemberManageItem';

export type TeamMemberEntry = {
  id: string;
  name: string;
  email: string;
  avatar: ComponentProps<typeof MemberManageItem.Member>['avatar'];
  joinedTeamCount: number;
  isMe: boolean;
};

type Props = {
  availableMembers: TeamMemberEntry[];
  addedMembers: TeamMemberEntry[];
  onAddMember: (id: string) => Promise<void>;
  onRemoveMember: (id: string) => Promise<void>;
  readonly: boolean;
};

export const TeamMembers = ({
  availableMembers,
  addedMembers,
  onAddMember,
  onRemoveMember,
  readonly,
}: Props) => {
  return (
    <SettingPageContainer title="メンバー設定">
      <TeamMembersSection
        title="メンバーを追加する"
        placeholder="追加可能なメンバーがいません"
        members={availableMembers}
        type="add"
        onAction={onAddMember}
        readonly={readonly}
      />
      <TeamMembersSection
        title="追加済みのメンバー"
        placeholder="まだメンバーが作成されていません"
        members={addedMembers}
        type="remove"
        onAction={onRemoveMember}
        readonly={readonly}
      />
    </SettingPageContainer>
  );
};
