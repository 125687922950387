import { ComponentProps } from 'react';
import { Avatar } from '../../../basics/Avatar/Avatar';

type Props = {
  name: string;
  email: string;
  avatar: Omit<ComponentProps<typeof Avatar>, 'size'>;
  isMe: boolean;
};

export const MemberItem = ({ name, email, avatar, isMe }: Props) => {
  return (
    <div className="grid max-w-full grid-cols-[auto_1fr] items-center gap-2 text-sm">
      <div className="h-[40px] w-[40px] overflow-hidden">
        <Avatar {...avatar} size={40} />
      </div>
      <div className="items-around grid h-full grid-rows-[auto_auto]">
        <div className="grid grid-cols-[auto_1fr]">
          <span className="truncate font-bold" title={name}>
            {name}
          </span>
          {isMe && (
            <span className="whitespace-nowrap text-xs text-sumi-500">
              （自分）
            </span>
          )}
        </div>
        <div className="truncate leading-4 text-sumi-500" title={email}>
          {email}
        </div>
      </div>
    </div>
  );
};
