import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

const backgroundColors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

export interface UserData {
  iconBackgroundColorNumber: number;
  notificationSettings?: UserNotificationSettings;
  plainTextMode: boolean;
  defaultInboxId: string | null;
  email: string;
  name: string;
  role: string;
  avatarURL: string;
  avatarStoragePath: string;
  companies: string[];
  lastLoggedInAt?: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const UserWebNotificationType = [
  'newMessage',
  'newComment',
  'mentionedMessage',
  'newChat',
  'opened',
] as const;

export type UserWebNotificationType = typeof UserWebNotificationType[number];

export type UserWebNotificationSettings = {
  enabled: boolean;
  types: UserWebNotificationType[];
};

export const UserSlackNotificationType = [
  'assigned',
  'mentionedMessage',
  'opened',
] as const;

export type UserSlackNotificationType =
  typeof UserSlackNotificationType[number];

export type UserSlackNotificationSettings = {
  enabled: boolean;
  types: UserSlackNotificationType[];
  channelId: string | null;
  channelName?: string;
};

export const UserChatworkNotificationType = [
  'assigned',
  'mentionedMessage',
] as const;

export type UserChatworkNotificationType =
  typeof UserChatworkNotificationType[number];

export type UserChatworkNotificationSettings = {
  enabled: boolean;
  types: UserChatworkNotificationType[];
  roomId: number | null;
};

export type UserNotificationSettings = {
  web?: UserWebNotificationSettings;
  slack?: UserSlackNotificationSettings;
  chatwork?: UserChatworkNotificationSettings;
};

export type PreferencesData = {
  threadView?: boolean;
  tagIndexes?: { [id: string]: number };
};

export const createPreferences = createEntityFactory<
  'Preferences',
  PreferencesData
>('Preferences');

export type Preferences = ReturnType<typeof createPreferences>;

export const preferencesConverter: FirestoreDataConverter<Preferences> =
  createConverter(createPreferences);

export type RefreshTokenData = {
  updatedAt: Timestamp;
};

export const createUser = createEntityFactory('User', (data: UserData) => ({
  get isAdmin() {
    return data.role === 'admin';
  },

  get isReadOnly() {
    return data.role === 'readonly';
  },

  get canDeleteMessage() {
    return data.role === 'admin' || data.role === 'member';
  },

  get plainTextMode() {
    return data.plainTextMode || false;
  },

  get notificationSettings() {
    return data.notificationSettings || {};
  },

  get webNotificationEnabled() {
    return this.notificationSettings?.web?.enabled || false;
  },

  get webNotificationTypes() {
    return this.notificationSettings?.web?.types || [];
  },

  get slackNotificationEnabled() {
    return this.notificationSettings?.slack?.enabled || false;
  },

  get slackNotificationChannelId() {
    return this.notificationSettings?.slack?.channelId || null;
  },

  get slackNotificationChannelName() {
    return this.notificationSettings?.slack?.channelName;
  },

  get slackNotificationTypes() {
    return this.notificationSettings?.slack?.types || [];
  },

  get chatworkNotificationEnabled() {
    return this.notificationSettings?.chatwork?.enabled || false;
  },

  get chatworkNotificationRoomId() {
    return this.notificationSettings?.chatwork?.roomId || null;
  },

  get chatworkNotificationTypes() {
    return this.notificationSettings?.chatwork?.types || [];
  },

  get iconName() {
    const name = data.name;
    if (name.length === 0) return '';
    return name.length > 1 ? (name[0] + name[1]).toUpperCase() : name[0];
  },

  get iconBackgroundColor() {
    return backgroundColors[
      data.iconBackgroundColorNumber % backgroundColors.length
    ];
  },
}));

export type User = ReturnType<typeof createUser>;

export const userConverter: FirestoreDataConverter<User> =
  createConverter(createUser);

export const ROLE_DEFINITIONS = {
  admin: {
    name: '管理者',
    description:
      '「メンバー」の権限に加え、ユーザーの招待・チームの作成・チームメンバーの招待・解除ができます',
  },
  member: {
    name: 'メンバー',
    description:
      'チームメンバーの招待や解除はできませんが、自分が登録されているチームの情報（テンプレートや署名の作成）は可能です',
  },
  noDelete: {
    name: 'メンバー(メール削除不可)',
    description:
      '基本的には「メンバー」の権限と同じですが、メールの削除ができません',
  },
  readonly: {
    name: '閲覧のみ',
    description:
      'メールの閲覧のみが可能なユーザーです。担当者への設定やコメント、チャットなどもできません',
  },
} as const;

export type Role = keyof typeof ROLE_DEFINITIONS;

export const ROLES: readonly Role[] = [
  'admin',
  'member',
  'noDelete',
  'readonly',
] as const;
