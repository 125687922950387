import { tv } from 'tailwind-variants';
import { ComponentProps, FC, ReactNode, useCallback, useState } from 'react';
import { Tooltip } from 'components/basics/Tooltip/Tooltip';
import { MemberItem } from '../MemberItem/MemberItem';

type Type = 'add' | 'remove';

type Props = {
  children: ReactNode;
};

type ButtonProps = {
  type: Type;
  id: string;
  disabled?: boolean;
  disabledReason?: string;
  onAction: (id: string, type: Type) => Promise<void> | void;
};

type Component = FC<Props> & {
  Member: FC<ComponentProps<typeof MemberItem>>;
  Button: FC<ButtonProps>;
};

const buttonText = tv({
  base: 'cursor-pointer whitespace-nowrap bg-transparent p-0 font-bold underline disabled:cursor-not-allowed disabled:text-sumi-500',
  variants: {
    type: {
      add: 'text-sea-500',
      remove: 'text-sun-500',
    },
  },
});

export const MemberManageItem: Component = ({ children }: Props) => {
  return (
    <div className="grid max-w-full grid-cols-[1fr_auto] items-center gap-2 text-sm">
      {children}
    </div>
  );
};

const Button: FC<ButtonProps> = ({
  id,
  type,
  onAction,
  disabled,
  disabledReason,
}) => {
  const [processing, setProcessing] = useState(false);
  const handleClick = useCallback(async () => {
    setProcessing(true);
    try {
      await onAction(id, type);
    } catch (e) {
      throw e;
    } finally {
      setProcessing(false);
    }
  }, [onAction, id, type]);
  return (
    <Tooltip
      content={disabledReason ?? ''}
      visible={!!disabled && !!disabledReason}
    >
      <button
        type="button"
        onClick={handleClick}
        className={buttonText({ type: type })}
        disabled={disabled || processing}
        aria-busy={processing}
      >
        {type === 'add' ? '追加' : '除外'}
      </button>
    </Tooltip>
  );
};

MemberManageItem.Member = MemberItem;
MemberManageItem.Button = Button;
