import { Avatar } from 'components/basics/Avatar/Avatar';
import { Comment } from '../../../Common/Comment';
import React from 'react';
import * as color from '../../../../color';
import { User } from 'lib';
import { useStore } from 'hooks/useStore';
import { DefaultButton } from '../../../Common/Button';

type Props = {
  user: User;
  onClick: () => void;
  inGroup?: boolean;
};

export const GroupMember = ({
  user,
  onClick,
  inGroup = false,
}: Props): JSX.Element => {
  const { me } = useStore();

  const isMe = user.id === me.id;

  return (
    <div className="mt-[10px] flex w-full items-center">
      <div className={`flex flex-1 items-center`}>
        <Avatar user={user} size={40} />
        <div className={`ml-[10px] flex max-w-[300px] flex-col justify-center`}>
          <div className={`text-[13px] font-bold text-[${color.common.text2}]`}>
            {user.name}
            {isMe && (
              <span
                className="text-[11px] font-normal"
                style={{ color: color.common.text3 }}
              >
                （自分）
              </span>
            )}
          </div>
          <Comment>{user.email}</Comment>
        </div>
      </div>
      <div className="ml-[12px]">
        <DefaultButton
          className={`h-[40px] w-[100px]`}
          type={inGroup ? 'danger' : 'primary'}
          onClick={onClick}
        >
          {inGroup ? '除外' : '追加'}
        </DefaultButton>
      </div>
    </div>
  );
};
