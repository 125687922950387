import { Button, Icon } from '../../../basics';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { ComponentProps, useCallback } from 'react';
import { InvitedMembersTable } from '../InvitedMembersTable/InvitedMembersTable';
import { CompanyMembersTable } from '../CompanyMembersTable/CompanyMembersTable';
import SimpleBar from 'simplebar-react';
import { useHistory } from 'react-router-dom';
import { Upgrade } from '../../../icons';

type Props = {
  invitePageHref: string;
  inviteDisabled: boolean;
  inviteDisabledReason?: string;
  upgraded: boolean;
  members: ComponentProps<typeof CompanyMembersTable>['members'];
  invitedMembers: ComponentProps<typeof InvitedMembersTable>['invitedMembers'];
  onDeleteInvite: (id: string) => Promise<void>;
  getDetailHref: (id: string) => string;
  canDeleteInvite: boolean;
};

export const CompanyMembers = ({
  invitePageHref,
  inviteDisabled,
  inviteDisabledReason,
  upgraded,
  members,
  invitedMembers,
  onDeleteInvite,
  getDetailHref,
  canDeleteInvite,
}: Props) => {
  const history = useHistory();
  const openInviteDrawer = useCallback(
    () => history.push(invitePageHref),
    [history, invitePageHref]
  );
  return (
    <div className="flex h-full flex-col items-start gap-8 pb-8 text-sm">
      <Tooltip
        content={inviteDisabledReason}
        visible={inviteDisabled && !!inviteDisabledReason}
      >
        <Button onClick={openInviteDrawer} disabled={inviteDisabled}>
          メンバーを招待
        </Button>
      </Tooltip>
      <div className="flex w-full flex-col gap-4">
        <div className="text-base font-bold">招待中のメンバー</div>
        {invitedMembers.length === 0 ? (
          <div className="text-sumi-500">招待中のメンバーはいません</div>
        ) : (
          <SimpleBar className="w-[900px] max-w-full" autoHide={false}>
            <InvitedMembersTable
              invitedMembers={invitedMembers}
              onDelete={onDeleteInvite}
              canDelete={canDeleteInvite}
            />
          </SimpleBar>
        )}
      </div>
      <div className="flex w-full flex-col gap-4">
        <div className="flex items-center gap-2">
          <div className="text-base font-bold">全体のメンバー</div>
          {!upgraded && (
            <Tooltip content="ビジネスプランにアップグレードすると最終ログイン日/作成日を確認できます。">
              <div>
                <Icon icon={Upgrade} className="block" />
              </div>
            </Tooltip>
          )}
        </div>
        <SimpleBar className="w-[900px] max-w-full" autoHide={false}>
          <CompanyMembersTable
            members={members}
            getHref={getDetailHref}
            upgraded={upgraded}
          />
        </SimpleBar>
      </div>
    </div>
  );
};
