import { useConversation } from './useConversation';
import React, { RefObject, useCallback, useMemo, useRef } from 'react';
import {
  Comment,
  DraftAttachment,
  extractEmail,
  extractNonInlineAttachments,
  extractNonInlineSentAttachments,
  isUnread,
  isUnreadComment,
  Message as MessageEntity,
  MessageLike,
  MessageStatus,
  Sent as SentEntity,
  StorageAttachment,
} from 'lib';
import { useAtomValue } from 'jotai';
import { companyAtom, meAtom } from '../../../../atoms/auth';
import { WriteBatch } from '../../../../utils/firestore';
import {
  addDoc,
  doc,
  DocumentReference,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getScrollContainerId, uploadFileToStorage } from '../util';
import { sortBy, throttle } from 'lodash';
import { companyCollection, companyDoc } from '../../../../firestore';
import { Draft as DraftEntity } from '../../../../firestore/entity/draft';
import { Lock } from '../../../../firestore/entity/lock';
import { Event as EventEntity } from '../../../../firestore/entity/event';
import {
  generateFileFromAttachment,
  generateStorageAttachmentName,
} from '../../../../util';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';
import { Button as AntdButton, Card, message as toast } from 'antd';
import { eventNames, logEvent } from '../../../../analytics';
import { animateScroll, scroller } from 'react-scroll';
import { usersAtom } from '../../../../atoms/firestore/user';
import { store } from '../../../../providers/StoreProvider';
import { messagesAtom } from '../../../../atoms/firestore/message';
import { uid as rcuid } from '../../CreateMessage/rcuid';
import { v4 as uuidv4 } from 'uuid';
import { inboxConverter } from '../../../../firestore/entity/inbox';
import mobxStore from '../../../../store/index';
import firebase from '../../../../firebase';
import { updateBatchMessagesStatusAtom } from '../../../../atoms/firestore/updateMessageStatus';
import { privateTeamAtom, teamsAtom } from '../../../../atoms/firestore/team';
import { CommentInputReplyProvider } from 'components/comment/CommentInput/CommentInputReplyProvider';
import { ConversationHeader } from './Header';
import ThreadConversationWrapper from './ThreadConversationWrapper';
import { AutoRepliedIndication } from './AutoRepliedIndication/AutoRepliedIndication';
import { ConversationCommentWithLogic } from './ConversationCommentWithLogic';
import Scheduled from './Scheduled';
import OtherMemberDraft from './OtherMemberDraft';
import { PCOrTabletOnly, SPOnly } from '../../../Common/MediaQuery';
import { ConversationCommentInput } from './ConversationCommentInput';
import {
  FrontMessageStatus,
  UnknownFrontMessageStatus,
} from '../../../../utils/frontMessageStatus';
import Sent from './Sent';
import { Draft } from './Draft';
import { MessageEvent } from '../../../../components/Message/MessageEvent/MessageEvent';
import Message from './Message';
import { Button } from '../../../../components/basics';

type Props = {
  threadView: boolean;
  messageId: string;
  sentId?: string;
  commentId?: string;
};

export const Conversation = (props: Props): JSX.Element => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  const scroll = useCallback((to: string) => {
    const offsetHeight = headerRef.current?.offsetHeight;
    if (!offsetHeight) {
      return;
    }
    scroller.scrollTo(to, {
      containerId: getScrollContainerId(),
      offset: -offsetHeight,
    });
  }, []);

  const onAddComment = useCallback((comment: Comment) => {
    setTimeout(() => scroll(`comment-${comment.id}`));
  }, []);

  const conversation = useConversation(
    props.threadView,
    props.messageId,
    (sent) => scroll(`sent-${sent.id}`),
    onAddComment
  );
  if (conversation.loading) {
    return <></>;
  }
  return (
    <InternalConversation
      {...props}
      {...conversation}
      setHeaderRef={(elem) => (headerRef.current = elem)}
      scroll={scroll}
    />
  );
};

type InternalProps = Props & {
  threadView: boolean;
  headerMessage: MessageLike;
  messages: MessageEntity[];
  sentMessages: SentEntity[];
  drafts: DraftEntity[];
  locks: Lock[];
  events: EventEntity[];
  comments: Comment[];
  setHeaderRef: (elem: HTMLDivElement | null) => void;
  scroll: (to: string) => void;
  fetchMoreComments: () => Promise<boolean>;
  hasMoreComments: boolean;
};

const InternalConversation = (props: InternalProps): JSX.Element => {
  const company = useAtomValue(companyAtom);
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const teams = useAtomValue(teamsAtom);
  const privateTeam = useAtomValue(privateTeamAtom);
  const threadView = props.threadView;

  const containerRef = useRef<HTMLDivElement>(null);
  const isPrivateTeam = props.headerMessage.teamId === privateTeam.id;

  const teamMembers = useMemo(() => {
    const team = teams.find((t) => t.id === props.headerMessage.teamId);
    return users.filter((u) => team?.isMember(u.id));
  }, [props.headerMessage, users, teams]);

  const lastMessage = useMemo(
    () =>
      [...props.messages, ...props.sentMessages]
        .sort((a, b) => a.date.diff(b.date))
        .at(-1)!,
    [props.messages, props.sentMessages]
  );

  const inMessages = !props.commentId && !props.sentId;

  const history = useHistory();
  const location = useLocation();
  const params = useParams<{
    teamId?: string;
    inboxId?: string;
    tagId?: string;
  }>();

  const showDialog = useConfirmDialog();

  const onLoad = useCallback(
    (prefix: string, id: string) => {
      if (id !== lastMessage?.id) {
        return;
      }
      setTimeout(() => props.scroll(`${prefix}-${id}`), 1000);
    },
    [lastMessage?.id, props.scroll]
  );

  const onMessageLoad = useCallback(
    (messageId: string) => onLoad('message', messageId),
    [onLoad]
  );

  const onSentLoad = useCallback(
    (messageId: string) => onLoad('sent', messageId),
    [onLoad]
  );

  const markAsRead = useCallback(async (message?: MessageEntity) => {
    const messages = message ? [message] : props.messages;
    const unreadMessages = messages.filter(
      (m) =>
        isUnread(m, me.id) ||
        !m.latestComment ||
        isUnreadComment(m, m.latestComment, me.id)
    );
    const batch = new WriteBatch(9);
    unreadMessages.forEach((m) => {
      const messageRef = m.ref;
      batch.update(messageRef, {
        [`readers.${me.id}`]: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    });
    await batch.commit();
  }, []);

  const scrollToAnchorSent = useCallback(() => {
    const sentId = props.sentId;
    if (sentId) {
      props.scroll(`sent-${sentId}`);
    }
  }, [props.sentId]);

  const scrollToAnchorComment = useCallback(() => {
    const commentId = props.commentId;
    if (commentId) {
      props.scroll(`comment-${commentId}`);
    }
  }, [props.commentId]);

  const scrollToBottom = useCallback(() => {
    animateScroll.scrollToBottom({
      containerId: getScrollContainerId(),
      duration: 300,
    });
  }, []);

  const startForwarding = useCallback(
    throttle(
      async (
        message: MessageEntity,
        storageMessageAttachments: StorageAttachment[]
      ) => {
        const inboxSnap = await getDoc(
          message.inboxRef.withConverter(inboxConverter)
        );
        const inbox = inboxSnap.data();
        if (!inbox) {
          console.error(`Inbox not found: inboxId=${inboxSnap.id}`);
          return;
        }

        const draftRef = doc(companyCollection('drafts'));
        const attachments = await Promise.all(
          extractNonInlineAttachments(
            storageMessageAttachments || message.attachments
          ).map(async (attachment) => {
            const file = (await generateFileFromAttachment(
              attachment
            )) as unknown as DraftAttachment;
            file.uid = rcuid();
            const storagePath = `companies/${company.id}/drafts/${
              draftRef.id
            }/attachments/${uuidv4()}/${generateStorageAttachmentName(
              file.name
            )}`;
            return await uploadFileToStorage(
              file,
              storagePath,
              me.id,
              inbox.teamId
            );
          })
        );

        // 自動cc
        const cc = inbox.autoCcs.map((acc) => acc);

        // 自動Bcc
        const bcc = inbox.autoBccs.filter(
          (abcc) => ![...cc].some((r) => extractEmail(abcc) === extractEmail(r))
        );

        const addDraftPromise = setDoc(draftRef, {
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to: [],
          cc,
          bcc,
          subject: `Fwd: ${message.subject}`,
          originalSubject: message.subject,
          body: '',
          signature: inbox.defaultSignatureId
            ? mobxStore.getSignature(inbox.defaultSignatureId)?.signature ||
              null
            : null,
          useQuote: true,
          attachments,
          plainTextMode: me.plainTextMode,
          drafter: me.id,
          inReplyToMessageId: message.id,
          inReplyToMessageRef: message.ref,
          isReply: true,
          isForwarded: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const addEventPromise = addDoc(companyCollection('events'), {
          user: me.id,
          name: me.name,
          teamId: message.teamId,
          messageId: message.id,
          type: 'draft:create:forward',
          text: `${me.name}が転送を開始しました。`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await Promise.all([addEventPromise, addDraftPromise]);
        logEvent(eventNames.start_forwarding);

        scrollToBottom();
      },
      2000,
      { trailing: false }
    ),
    [company, me, scrollToBottom]
  );

  const startReply = useCallback(
    throttle(
      async (message: MessageEntity, replyAll: boolean) => {
        const inboxSnap = await getDoc(
          message.inboxRef.withConverter(inboxConverter)
        );
        const inbox = inboxSnap.data();
        if (!inbox) {
          console.error(`Inbox not found: inboxId=${inboxSnap.id}`);
          return;
        }
        if (
          message.assignee == null &&
          teams.some((team) => team.id === message.teamId)
        ) {
          await updateDoc(message.ref, {
            assignee: me.id,
            updatedAt: serverTimestamp(),
          });
        }

        let to: string[] = [];
        if (
          message.replyAddress &&
          inbox.email !== extractEmail(message.replyAddress)
        ) {
          to = [message.replyAddress];
        } else if (
          inbox.email === extractEmail(message.replyAddress) &&
          message.toAddresses.length > 0
        ) {
          to = [message.toAddresses[0]];
        }

        if (replyAll) {
          to = [
            ...to,
            ...message.toAddresses.filter(
              (c) =>
                !to.some((t) => extractEmail(c) === extractEmail(t)) &&
                extractEmail(c) !== inbox.email
            ),
          ];
        }

        let cc: string[] = [];
        if (replyAll) {
          // toと重複する場合はセットしない
          cc = message.ccAddresses.filter(
            (c) => !to.some((t) => extractEmail(c) === extractEmail(t))
          );
        }
        // 自動cc
        cc = [
          ...cc,
          ...inbox.autoCcs.filter(
            (acc) =>
              !cc.some((c) => extractEmail(c) === acc) &&
              !to.some((t) => extractEmail(acc) === extractEmail(t))
          ),
        ];

        // 自動Bcc
        const bcc = inbox.autoBccs.filter(
          (abcc) =>
            ![...to, ...cc].some((r) => extractEmail(abcc) === extractEmail(r))
        );

        const addDraftPromise = addDoc(companyCollection('drafts'), {
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to,
          cc,
          bcc,
          subject: `${message.subject.startsWith('Re: ') ? '' : 'Re: '}${
            message.subject
          }`,
          originalSubject: message.subject,
          body: '',
          signature: inbox.defaultSignatureId
            ? mobxStore.getSignature(inbox.defaultSignatureId)?.signature ||
              null
            : null,
          useQuote: true,
          attachments: [],
          plainTextMode: me.plainTextMode,
          drafter: me.id,
          inReplyToMessageId: message.id,
          inReplyToMessageRef: message.ref,
          isReply: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const addLockPromise = await addDoc(companyCollection('locks'), {
          teamId: message.teamId,
          messageId: message.id,
          inboxId: inbox.id,
          locker: me.id,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const addEventPromise = await addDoc(companyCollection('events'), {
          user: me.id,
          name: me.name,
          teamId: message.teamId,
          messageId: message.id,
          type: 'draft:create',
          text: `${me.name}が返信を開始しました。`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        await Promise.all([addEventPromise, addDraftPromise, addLockPromise]);
        logEvent(eventNames.start_reply);

        scrollToBottom();
      },
      2000,
      { trailing: false }
    ),
    [teams, me, scrollToBottom]
  );

  const startForwardingToSent = useCallback(
    throttle(
      async (sent: SentEntity) => {
        const inboxSnap = await getDoc(
          companyDoc('inboxes', sent.inboxId, inboxConverter)
        );
        const inbox = inboxSnap.data();
        if (!inbox) {
          console.error(`Inbox not found: inboxId=${inboxSnap.id}`);
          return;
        }

        const draftRef = doc(companyCollection('drafts'));
        const attachments = await Promise.all(
          extractNonInlineSentAttachments(sent.attachments).map(
            async (attachment) => {
              const ref = firebase.storage().ref(attachment.storagePath);
              const url = await ref.getDownloadURL();
              const res = await fetch(url);
              const blob = await res.blob();
              const file = new File([blob], attachment.name, {
                type: attachment.type,
              }) as unknown as DraftAttachment;
              file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
              const storagePath = `companies/${company.id}/drafts/${
                draftRef.id
              }/attachments/${uuidv4()}/${generateStorageAttachmentName(
                file.name
              )}`;
              return await uploadFileToStorage(
                file,
                storagePath,
                me.id,
                inbox.teamId
              );
            }
          )
        );

        // 自動cc
        const cc = inbox.autoCcs.map((acc) => acc);

        // 自動Bcc
        const bcc = inbox.autoBccs.filter(
          (abcc) => ![...cc].some((r) => extractEmail(abcc) === extractEmail(r))
        );

        const addDraftPromise = setDoc(draftRef, {
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to: [],
          cc,
          bcc,
          subject: `Fwd: ${sent.subject}`,
          originalSubject: sent.subject,
          body: '',
          signature: inbox.defaultSignatureId
            ? mobxStore.getSignature(inbox.defaultSignatureId)?.signature ||
              null
            : null,
          useQuote: true,
          attachments,
          plainTextMode: me.plainTextMode,
          drafter: me.id,
          inReplyToMessageId: sent.inReplyToMessageId,
          inReplyToMessageRef: companyDoc('messages', sent.inReplyToMessageId!),
          inReplyToSentId: sent.id,
          inReplyToSentRef: sent.ref,
          isReply: true,
          isForwarded: true,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const addEventPromise = addDoc(companyCollection('events'), {
          user: me.id,
          name: me.name,
          teamId: sent.teamId,
          messageId: sent.inReplyToMessageId,
          type: 'draft:create:forward',
          text: `${me.name}が転送を開始しました。`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await Promise.all([addEventPromise, addDraftPromise]);
        logEvent(eventNames.start_forwarding_to_sent);

        scrollToBottom();
      },
      2000,
      { trailing: false }
    ),
    [company, me, scrollToBottom]
  );

  const startReplyToSent = useCallback(
    throttle(
      async (sent: SentEntity, replyAll: boolean) => {
        const inboxSnap = await getDoc(
          companyDoc('inboxes', sent.inboxId, inboxConverter)
        );
        const inbox = inboxSnap.data();
        if (!inbox) {
          console.error(`Inbox not found: inboxId=${inboxSnap.id}`);
          return;
        }

        const sentTo = sent.to ?? [];
        const to =
          (replyAll ? sentTo : sentTo.length > 0 ? sentTo.slice(0, 1) : null) ||
          [];

        let cc: string[] = [];
        if (replyAll && sent.cc) {
          // toと重複する場合はセットしない
          cc = sent.cc.filter(
            (c) => !to.some((t) => extractEmail(c) === extractEmail(t))
          );
        }

        // 自動cc
        cc = [
          ...cc,
          ...inbox.autoCcs.filter(
            (acc) =>
              !cc.some((c) => extractEmail(c) === acc) &&
              !to.some((t) => extractEmail(acc) === extractEmail(t))
          ),
        ];

        // 自動Bcc
        const bcc = inbox.autoBccs.filter(
          (abcc) =>
            ![...to, ...cc].some((r) => extractEmail(abcc) === extractEmail(r))
        );

        const addDraftPromise = addDoc(companyCollection('drafts'), {
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to,
          cc,
          bcc,
          subject: `${sent.subject.startsWith('Re: ') ? '' : 'Re: '}${
            sent.subject
          }`,
          originalSubject: sent.subject,
          body: '',
          signature: inbox.defaultSignatureId
            ? mobxStore.getSignature(inbox.defaultSignatureId)?.signature ||
              null
            : null,
          useQuote: true,
          attachments: [],
          plainTextMode: me.plainTextMode,
          drafter: me.id,
          inReplyToMessageId: sent.inReplyToMessageId,
          inReplyToMessageRef: companyDoc('messages', sent.inReplyToMessageId!),
          inReplyToSentId: sent.id,
          inReplyToSentRef: sent.ref,
          isReply: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const addLockPromise = addDoc(companyCollection('locks'), {
          teamId: sent.teamId,
          messageId: sent.inReplyToMessageId,
          inboxId: inbox.id,
          locker: me.id,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const addEventPromise = addDoc(companyCollection('events'), {
          user: me.id,
          name: me.name,
          teamId: sent.teamId,
          messageId: sent.inReplyToMessageId,
          type: 'draft:create',
          text: `${me.name}が返信を開始しました。`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await Promise.all([addEventPromise, addDraftPromise, addLockPromise]);
        logEvent(eventNames.start_reply_to_sent);
        scrollToBottom();
      },
      2000,
      {
        trailing: false,
      }
    ),
    [company, me, scrollToBottom]
  );

  const toLink = useCallback(
    (message: MessageLike | null) => {
      let url;
      if (location.pathname.startsWith('/me/assigned')) {
        url = `/me/assigned/messages`;
      }
      const { teamId, inboxId, tagId } = params;
      if (teamId && inboxId && tagId) {
        url = `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/messages`;
      } else if (teamId && tagId) {
        url = `/teams/${teamId}/tags/${tagId}/messages`;
      } else if (teamId) {
        url = `/teams/${teamId}/messages`;
      }
      if (url) {
        if (threadView) {
          return message ? `${url}/${message.id}?view=thread` : url;
        } else {
          return message ? `${url}/${message.id}` : url;
        }
      }
      return null;
    },
    [threadView, location, params]
  );

  const backLink = useCallback(() => {
    const matched = [
      '/me/drafts',
      '/me/scheduled',
      '/me/sent',
      '/me/mentioned',
      '/me/assigned/messages',
      '/me/assigned/processed',
      '/me/assigned/all',
      '/me/commented',
      '/me/starred',
    ].find((pathPrefix) => location.pathname.startsWith(pathPrefix));
    if (matched) {
      return matched;
    }
    return '.';
  }, [location]);

  const toNextMessage = useCallback(() => {
    const messagesPaginate = store.get(messagesAtom);
    if (messagesPaginate.state !== 'hasData') {
      return;
    }
    const allMessages = messagesPaginate.data;

    let nextLink: string | null;
    // 次の未対応のメッセージを表示する
    // 現在のメッセージの次の順番を取得する
    const currentMessageIndex = allMessages.findIndex(
      (m) => m.id === props.messageId
    );
    if (currentMessageIndex === -1) {
      return;
    }
    const nextMessageIndex = currentMessageIndex + 1;
    const hasNextMessage = allMessages.length > nextMessageIndex;
    const anotherMessages = allMessages.filter((m) => m.id !== props.messageId);
    if (hasNextMessage) {
      // 次のメッセージがある場合、自動で遷移する
      nextLink = toLink(allMessages[nextMessageIndex]);
    } else if (anotherMessages.length > 0) {
      // 次のメッセージがない場合、他のメッセージがあれば自動で先頭に遷移する
      nextLink = toLink(anotherMessages[0]);
    } else {
      // メッセージがなにもない場合はメッセージ詳細を消す
      nextLink = toLink(null);
    }
    if (nextLink) {
      history.push(nextLink);
    }
  }, [props.messageId]);

  const updateStatus = useCallback(
    async (status: FrontMessageStatus | UnknownFrontMessageStatus) => {
      let messages = props.messages;
      const message = messages.at(-1)!;
      if (props.headerMessage.status === MessageStatus.Processed) {
        messages = [message];
      }

      await store.set(updateBatchMessagesStatusAtom, {
        messages,
        status,
      });

      const {
        selectedStatus,
        searchStore: { inSearch },
      } = mobxStore;
      const statusToProcessed = status.statusType === MessageStatus.Processed;

      if (statusToProcessed && selectedStatus === '未対応' && !inSearch) {
        toNextMessage();
      }
    },
    [props.messages, props.headerMessage, toNextMessage]
  );

  const handleRemoveTag = useCallback(
    (tagId: string) => {
      const { tagId: inboxTagId } = params;
      if (tagId !== inboxTagId) {
        return;
      }
      toNextMessage();
    },
    [toNextMessage]
  );

  const updateAssignee = useCallback(
    async (assigneeId: string | null) => {
      const promises: Promise<DocumentReference | void>[] = [];
      const assignee = assigneeId
        ? users.find((u) => u.id === assigneeId)
        : undefined;
      const lastMessage = props.messages.at(-1)!;
      const messages = assignee
        ? [lastMessage]
        : props.messages.filter((m) => m.assignee);
      messages.forEach((m) =>
        promises.push(
          updateDoc(m.ref, {
            assignee: assigneeId,
            updatedAt: serverTimestamp(),
          })
        )
      );
      promises.push(
        addDoc(companyCollection('events'), {
          user: me.id,
          name: me.name,
          assignee: assigneeId,
          assigneeName: assignee?.name || '担当者未設定',
          teamId: lastMessage.teamId,
          messageId: lastMessage.id,
          type: 'assignee:update',
          text: `${me.name}が「${
            assignee?.name || '担当者未設定'
          }」に担当者を設定しました。`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        })
      );
      await Promise.all(promises);
      logEvent(eventNames.update_assignee, { assignee: assigneeId });
    },
    [props.messages]
  );

  const markAsDeleted = useCallback(
    async (messages: MessageEntity[], deleted: boolean) => {
      const batch = new WriteBatch(9);
      messages.forEach((m) =>
        batch.update(m.ref, {
          deleted,
          updatedAt: serverTimestamp(),
        })
      );
      await batch.commit();
    },
    []
  );

  const restoreMessages = useCallback(
    async (messages: MessageEntity[]) => {
      toast.destroy();
      const subject = messages[0].subject;
      await markAsDeleted(messages, false);
      history.push('./');
      toast.success(
        <>
          ゴミ箱のメール "{subject}"（件名） を元に戻しました
          <AntdButton type="link" onClick={() => markAsDeleted(messages, true)}>
            取り消す
          </AntdButton>
        </>
      );
    },
    [markAsDeleted]
  );

  const markMessageAsDeleted = useCallback(
    async (message: MessageEntity) => {
      toast.destroy();
      await updateDoc(message.ref, {
        deleted: true,
        updatedAt: serverTimestamp(),
      });
      if (!props.messages.length) {
        history.push('./');
      }
      toast.success(
        <>
          メール "{message.subject}"（件名） をゴミ箱に移動しました
          <AntdButton type="link" onClick={() => restoreMessages([message])}>
            取り消す
          </AntdButton>
        </>
      );
    },
    [props.messages, restoreMessages]
  );

  const deleteMessages = useCallback(async (messages: MessageEntity[]) => {
    const subject = messages[0].subject;
    showDialog({
      title: `メール "${subject}"（件名） を削除しますか？`,
      description: (
        <div>
          <p>
            一度削除すると元に戻せません。
            <br />
            下記のコンテンツが削除されます。
          </p>
          <Card title={'削除対象のコンテンツ'}>
            ・メール
            <br />
            ・メールへの返信中の下書き
            <br />
            ・この会話のコメント
            <br />
            ・この会話のイベント（例：〇〇が返信を開始しました。）
            <br />
          </Card>
        </div>
      ),
      onOk: async () => {
        const batch = new WriteBatch(9);
        messages.forEach((m) => {
          batch.delete(m.ref);
        });
        await batch.commit();
        logEvent(eventNames.remove_message);
        history.push('./');
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
    });
  }, []);

  const {
    sentId,
    commentId,
    headerMessage,
    messages,
    sentMessages,
    drafts,
    locks,
    events,
    comments,
    setHeaderRef,
    hasMoreComments,
    fetchMoreComments: fetchMore,
  } = props;

  const fetchMoreComments = useFetchMoreComments(fetchMore, containerRef);

  const lock = locks.length > 0 ? locks[0] : null;

  const conversationCacheRef = useRef(
    new Map<
      string,
      MessageEntity | SentEntity | DraftEntity | EventEntity | Comment
    >()
  );
  const conversations: (
    | MessageEntity
    | SentEntity
    | DraftEntity
    | EventEntity
    | Comment
  )[] = useMemo(() => {
    let conversations: (
      | MessageEntity
      | SentEntity
      | DraftEntity
      | EventEntity
      | Comment
    )[] = [messages, sentMessages, events, comments].flat();
    conversations.sort((a, b) => a.date.diff(b.date));
    const sortedDrafts = drafts
      .slice()
      .sort((a, b) => a.date.diff(b.date))
      .map((draft) => draft.clone({ status: headerMessage.status }));
    conversations = [...conversations, ...sortedDrafts];

    return conversations.map((c) => {
      const cache = conversationCacheRef.current.get(c.id);
      if (cache && cache.updatedAt.toMillis() === c.updatedAt.toMillis()) {
        return cache;
      }
      conversationCacheRef.current.set(c.id, c);
      return c;
    });
  }, [messages, sentMessages, events, comments, drafts, headerMessage.status]);
  const isReadOnly = me.isReadOnly;

  // コメント先のメッセージは常に一番新しいメッセージにする
  const commentTargetMessage = useMemo(
    () => sortBy(messages, (m) => m.createdAt.toMillis()).at(-1)!,
    [messages]
  );

  const firstCommentId = useMemo(() => {
    return conversations.find((c) => c._type === 'Comment')?.id;
  }, [conversations]);

  return (
    <CommentInputReplyProvider>
      <div
        id="ConversationContainer"
        className="flex h-[100dvh] w-full flex-col overflow-auto sm:h-full"
        ref={containerRef}
      >
        <ConversationHeader
          message={headerMessage}
          messages={messages}
          locked={Boolean(lock)}
          updateStatus={updateStatus}
          teamMembers={teamMembers}
          users={users}
          updateAssignee={updateAssignee}
          restoreMessages={() => restoreMessages(messages)}
          deleteMessages={() => deleteMessages(messages)}
          backLink={backLink}
          onRemoveTag={handleRemoveTag}
          ref={setHeaderRef}
        />
        <div className="flex flex-1 flex-col bg-[#F8F8FB]">
          <ThreadConversationWrapper>
            {headerMessage.isAutoReplied && <AutoRepliedIndication />}

            {conversations.map((conversation) => {
              if (conversation._type === 'Message') {
                return (
                  <Message
                    key={conversation.id}
                    message={conversation}
                    lock={lock}
                    startReply={startReply}
                    startForwarding={startForwarding}
                    markAsRead={() => markAsRead(conversation)}
                    scrollToAnchorComment={scrollToAnchorComment}
                    scrollToBottom={scrollToBottom}
                    markMessageAsDeleted={markMessageAsDeleted}
                    restoreMessage={() => restoreMessages([conversation])}
                    deleteMessage={() => deleteMessages([conversation])}
                    collapsed={inMessages && conversation !== lastMessage}
                    onLoad={onMessageLoad}
                  />
                );
              } else if (conversation._type === 'Sent') {
                return (
                  <Sent
                    sent={conversation}
                    startReply={startReplyToSent}
                    startForwarding={startForwardingToSent}
                    scrollToAnchorSent={scrollToAnchorSent}
                    scrollToBottom={scrollToBottom}
                    lock={lock}
                    me={me}
                    getUser={mobxStore.getUser}
                    key={conversation.id}
                    highlight={conversation.id === sentId}
                    collapsed={inMessages && conversation !== lastMessage}
                    onLoad={onSentLoad}
                    isReadOnly={isReadOnly}
                    canDeleteMessage={me.canDeleteMessage || isPrivateTeam}
                  />
                );
              } else if (conversation._type === 'Comment') {
                return (
                  <div key={conversation.id} className="my-4">
                    {firstCommentId === conversation.id && hasMoreComments && (
                      <div className="my-4 flex w-full items-center">
                        <Button variant="text" onClick={fetchMoreComments}>
                          コメントをもっと見る
                        </Button>
                      </div>
                    )}
                    <div data-top-comment={firstCommentId === conversation.id}>
                      <ConversationCommentWithLogic
                        comment={conversation}
                        highlight={conversation.id === commentId}
                      />
                    </div>
                  </div>
                );
              } else if (conversation._type === 'Draft') {
                // 予約送信
                if (conversation.isScheduled) {
                  return (
                    <Scheduled draft={conversation} key={conversation.id} />
                  );
                }

                // 他のメンバーの下書き
                if (conversation.drafter !== me.id) {
                  return (
                    <OtherMemberDraft
                      draft={conversation}
                      key={conversation.id}
                    />
                  );
                }

                // 下書き
                return <Draft draft={conversation} key={conversation.id} />;
              } else if (conversation._type === 'Event') {
                // イベント
                return (
                  <MessageEvent
                    key={conversation.id}
                    type={conversation.type}
                    date={conversation.date.toDate()}
                    text={conversation.text}
                    className="m-0 p-[8px_10px] sm:m-[16px_10px] sm:p-[0_6px]"
                  />
                );
              }
              // 基本起こり得ない
              return <div />;
            })}
          </ThreadConversationWrapper>
          <SPOnly>
            <div className="sticky bottom-0 z-10 bg-sumi-50 p-4">
              <ConversationCommentInput
                messageId={commentTargetMessage.id}
                threadId={commentTargetMessage.threadId}
                inboxId={commentTargetMessage.inboxId}
                teamId={headerMessage.teamId}
              />
            </div>
          </SPOnly>
        </div>
        {/* コメント追加 */}
        <PCOrTabletOnly>
          <div className="sticky bottom-0 z-10 bg-sumi-50 p-4">
            <ConversationCommentInput
              messageId={commentTargetMessage.id}
              threadId={commentTargetMessage.threadId}
              inboxId={commentTargetMessage.inboxId}
              teamId={headerMessage.teamId}
            />
          </div>
        </PCOrTabletOnly>
      </div>
    </CommentInputReplyProvider>
  );
};

/**
 * コメントを読み込みつつ、読み込んだ分下にスクロールさせる
 */
const useFetchMoreComments = (
  fetchMore: () => Promise<boolean>,
  containerRef: RefObject<HTMLDivElement | null>
): (() => Promise<void>) => {
  return useCallback(async () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const topComment = container.querySelector<HTMLDivElement>(
      'div[data-top-comment=true]'
    );
    if (!topComment) {
      return;
    }
    const beforeOffset = topComment.offsetTop;
    await fetchMore();
    const scrollAmount = topComment.offsetTop - beforeOffset;
    container.scrollBy(0, scrollAmount);
  }, [fetchMore]);
};
