import React, { Component } from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Card, Checkbox, Dropdown, Menu, message as toast, Modal } from 'antd';
import firebase, { db } from '../../../../../firebase';
import JSZip from 'jszip';
import * as Sentry from '@sentry/react';
import { eventNames, logEvent } from '../../../../../analytics';
import { WriteBatch } from '../../../../../utils/firestore';
import { Icon, Loading } from '../../../../../components/basics';
import { CaretDown } from '../../../../../components/icons';

const { confirm } = Modal;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoving: false,
    };
  }

  downloadAsZip = async () => {
    const getNameContentPairsFrom = async (urlAndFilenames) => {
      return await Promise.all(
        urlAndFilenames.map(async (urlAndFilename) => {
          const response = await fetch(urlAndFilename.url);
          const content = await response.blob();
          return { name: urlAndFilename.filename, content };
        })
      );
    };

    const generateZipBlob = (nameContentPairs, name) => {
      const zip = new JSZip();

      const folder = zip.folder(restrictFileName(name));

      nameContentPairs.forEach((nameContentPair) => {
        const name = restrictFileName(nameContentPair.name);
        const content = nameContentPair.content;
        folder.file(name, content);
      });

      return zip.generateAsync({ type: 'blob' }); // デフォルトで無圧縮
    };

    const saveBlob = (blob, name) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = restrictFileName(name) + '.zip';

      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    /**
     * Windows のファイル名に使用できない文字をエスケープ
     * Mac や Linux より Windows の方がファイル名の制限が厳しいため、Windows に合わせる
     */
    const restrictFileName = (name) =>
      name.replace(/[\\/:*?"<>|]/g, (c) => '%' + c.charCodeAt(0).toString(16));

    try {
      const name = 'eml';

      const { checkedSent } = this.props.store;
      const canDownloadSent = checkedSent.filter((m) => m.emlStoragePath);
      toast.info(`${canDownloadSent.length}件のダウンロードを開始しました`);

      const messageUrlAndFilenames = (
        await Promise.all(
          canDownloadSent
            .filter((s) => s.isReply && !s.isInReplyToMessageDeleted)
            .map(async (s) => {
              const messageSnapshot = await db
                .collection(
                  `/companies/${this.props.store.signInCompany}/messages`
                )
                .doc(s.inReplyToMessageId)
                .get();
              if (
                !messageSnapshot.exists ||
                !messageSnapshot.data().emlStoragePath
              )
                return null;

              const ref = firebase
                .storage()
                .ref(messageSnapshot.data().emlStoragePath);
              const url = await ref.getDownloadURL();
              const subject =
                messageSnapshot.data().subject?.length > 0
                  ? messageSnapshot.data().subject
                  : 'no_subject';
              return { url, filename: `${subject.substring(0, 70)}.eml` };
            })
        )
      ).filter((s) => s);

      const sentUrlAndFilenames = await Promise.all(
        canDownloadSent.map(async (s) => {
          const ref = firebase.storage().ref(s.emlStoragePath);
          const url = await ref.getDownloadURL();
          const subject = s.subject.length > 0 ? s.subject : 'no_subject';
          return { url, filename: `${subject.substring(0, 70)}.eml` };
        })
      );

      let urlAndFilenames = [...messageUrlAndFilenames, ...sentUrlAndFilenames];

      urlAndFilenames = urlAndFilenames.reduce(
        (previousValue, currentValue) => {
          let num = 0;

          // splitExt('img.jpg');  // => [ 'img',   'jpg'  ]
          // splitExt('.html');    // => [ '',      'html' ]
          // splitExt('lib.d.ts'); // => [ 'lib.d', 'ts'   ]
          // splitExt('abc');      // => [ 'abc'           ]
          const splitExt = (filename) => filename.split(/\.(?=[^.]+$)/);
          const hasSameFilename = (filename) =>
            previousValue.some((pv) => pv.filename === filename);
          const generateUniqueFilename = (filename) => {
            if (!hasSameFilename(filename)) return filename;
            num++;
            const split = splitExt(currentValue.filename); // "file.eml"
            return generateUniqueFilename(
              split[0] + '_' + num + '.' + split[1]
            );
          };
          return [
            ...previousValue,
            {
              ...currentValue,
              filename: generateUniqueFilename(currentValue.filename),
            },
          ];
        },
        []
      );

      const zipBlob = await generateZipBlob(
        await getNameContentPairsFrom(urlAndFilenames),
        name
      );
      saveBlob(zipBlob, name);
      logEvent(eventNames.batch_download_sent_eml);
      toast.success(`${canDownloadSent.length}件のダウンロードを完了しました`);
    } catch (e) {
      toast.error('ダウンロードに失敗しました');
      Sentry.captureMessage(e);
      console.error('SentCheckOptions.downloadAsZip:', e);
    }
  };

  toSentListLink = () => {
    const match = this.props.match;
    return decodeURI(generatePath(match.path, match.params));
  };

  removeCheckedSent = async () => {
    const { checkedSent } = this.props.store;
    confirm({
      title: ` ${checkedSent.length}件の選択中の送信済みのメールを削除しますか？`,
      content: (
        <div>
          <p>
            一度削除すると元に戻せません。
            <br />
            下記のコンテンツが削除されます。
          </p>
          <Card title={'削除対象のコンテンツ'}>
            ・送信済みのメール（添付ファイルを含む）
            <br />
            ・送信済みのメールへの返信中の下書き
            <br />
          </Card>
        </div>
      ),
      onOk: async () => {
        this.props.history.push(this.toSentListLink());
        this.setState({ isRemoving: true });
        const batch = new WriteBatch(9);
        checkedSent.map((m) => m.ref).forEach((ref) => batch.delete(ref));
        await batch.commit();

        // 検索中の場合は検索結果から除外する
        const searchStore = this.props.store.searchStore;
        if (searchStore.inSearch) {
          const checkedSentIds = checkedSent.map((s) => s.id);
          searchStore.unsortedSent = searchStore.unsortedSent.filter(
            (sent) => !checkedSentIds.includes(sent.id)
          );
        }

        this.setState({ isRemoving: false });
        toast.success(`${checkedSent.length}件を削除しました`);
        logEvent(eventNames.batch_remove_sent);
        this.props.store.checkedSent = [];
      },
      onCancel: () => {},
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  onCheckAllChange = (e) => {
    const { searchedSentList } = this.props;
    if (e.target.checked) {
      // すべて選択する
      this.props.store.checkedSent = searchedSentList;
      return;
    }
    // すべて選択を解除する
    this.props.store.checkedSent = [];
  };

  isAllChecked = () => {
    const { searchedSentList } = this.props;
    return searchedSentList.every((ss) =>
      this.props.store.checkedSent.some((cs) => cs.id === ss.id)
    );
  };

  render() {
    const { checkedSent } = this.props.store;
    const { isRemoving } = this.state;

    const isAllChecked = this.isAllChecked();
    const teamId = this.props.match.params.teamId;
    const isPrivateTeam = teamId === this.props.store.privateTeam.id;
    const canDeleteMessage =
      this.props.store.me.canDeleteMessage || isPrivateTeam;
    return (
      <div
        data-testid="checked-dropdown"
        className={'flex items-center justify-between bg-sumi-50 px-3 py-2'}
      >
        <div className={'flex items-center gap-2'}>
          <Checkbox
            indeterminate={checkedSent.length > 0 && !isAllChecked}
            checked={isAllChecked}
            onChange={this.onCheckAllChange}
            disabled={isRemoving}
          />
          <span>{checkedSent.length}件を選択中</span>
        </div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                data-testid="download-email"
                onClick={this.downloadAsZip}
              >
                ダウンロードする
              </Menu.Item>
              <Menu.Item
                key="2"
                data-testid="move-to-trash"
                onClick={this.removeCheckedSent}
                disabled={!canDeleteMessage}
              >
                ゴミ箱に移動する
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          {isRemoving ? (
            <Loading />
          ) : (
            <button
              type="button"
              className="m-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-[50%] bg-transparent p-0 text-[11px] text-[#545454] hover:bg-[#f0f0f1]"
            >
              <Icon icon={CaretDown} size={20} />
            </button>
          )}
        </Dropdown>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
