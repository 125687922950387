import React, { useState } from 'react';
import { MessageLike, MessageStatus, Tag, User } from 'lib';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../hooks/useStore';
import { Button, Card, Checkbox, Dropdown, Menu, message as toast } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isDeletedPaneLocation } from '../../../../../../utils/messagePane';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { eventNames, logEvent } from '../../../../../../analytics';
import { downloadBlob } from '../../../../../../util';
import { uniq } from 'lodash';
import JSZip from 'jszip';
import { fetchQueryInArray } from '../../../../../../utils/firestore';
import firebase, { db } from '../../../../../../firebase';
import * as Sentry from '@sentry/react';
import { TagSubOptions } from '../tagSubOptions';
import { AssigneeSubOptions } from '../assigneeSubOptions';
import { useUpdateAllProcessed } from './useUpdateAllProcessed';
import { Icon, Loading } from '../../../../../../components/basics';
import { CaretDown } from '../../../../../../components/icons';
import {
  deleteMessagesFunction,
  markDeletingMessagesFunction,
} from '../../../../../../functions';
import { useAtomValue } from 'jotai';
import { companyAtom, meAtom } from '../../../../../../atoms/auth';
import {
  joinedTeamIdsAtom,
  privateTeamAtom,
} from '../../../../../../atoms/firestore/team';
import { v4 } from 'uuid';
import { useConfirmDialog } from '../../../../../../hooks/confirmDialog';
import { allTeamStatusesInGroupAtom } from '../../../../../../atoms/firestore/customStatuses';
import { getStatusesFromView } from '../../../../../../utils/frontMessageStatus';

type Props = {
  messages: MessageLike[];
};

type NameContentPair = {
  name: string;
  content: Blob;
};

type UrlAndFilename = {
  url: string;
  filename: string;
};

type SearchParams = {
  inSearch: boolean;
  companyId: string;
  keywords?: string[];
  teamIds: string[];
  tags?: string[];
  inboxTags?: string[];
  inboxId?: string;
  status?: string;
  customStatusIds?: string[];
  from?: string;
  to?: string;
  subjectOrText?: string;
  assignee?: string | null;
  after?: string;
  before?: string;
  hasAttachments?: boolean;
  attachmentsFilename?: string;
  deleted: boolean;
};

export const CheckOptions = observer(({ messages }: Props) => {
  const [isUpdating, setUpdating] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const store = useStore();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{
    teamId?: string;
    inboxId?: string;
    tagId?: string;
  }>();
  const { teamId, inboxId, tagId } = match.params;
  const { updateAllToProcessed } = useUpdateAllProcessed(store);
  const company = useAtomValue(companyAtom);
  const joinedTeamIds = useAtomValue(joinedTeamIdsAtom);
  const me = useAtomValue(meAtom);
  const privateTeam = useAtomValue(privateTeamAtom);
  const [, allTeamStatusesInGroup] = useAtomValue(allTeamStatusesInGroupAtom);

  const confirm = useConfirmDialog();

  const isPrivateTeam = teamId === privateTeam.id;
  const canDeleteMessage = me.canDeleteMessage || isPrivateTeam;

  const updateStatus = async (status: string) => {
    setUpdating(true);

    // すでに同一ステータスの場合、更新しない
    const messages = store.checkedMessages.filter((m) => m.status !== status);
    let messagesData: { teamId: string; messageId: string }[];
    if (store.isInThreadView) {
      if (status === '対応済み') {
        // Set all messages to 対応済み.
        messagesData = messages
          .map((t) =>
            t.asThread().messages.map((messageId) => ({
              teamId: t.teamId,
              messageId,
            }))
          )
          .flat();
      } else {
        // Only set the status of the last message to 未対応.
        messagesData = messages.map((t) => ({
          teamId: t.teamId,
          messageId: t.asThread().messages.slice(-1)[0],
        }));
      }
    } else {
      messagesData = messages.map((m) => ({
        teamId: m.teamId,
        messageId: m.id,
      }));
    }
    await store.messageStore.updateStatuses(messagesData, status);

    setUpdating(false);
    toast.success(`${messages.length}件を${status}にしました`);
    logEvent(eventNames.batch_update_status, { status });
    store.checkedMessages = [];
  };

  const updateTag = async (tag: Tag) => {
    setUpdating(true);

    // すでにタグが付与されている場合、更新しない
    const messages = store.checkedMessages.filter(
      (m) => !m.tags.includes(tag.id)
    );

    let messageIds: string[];
    if (store.isInThreadView) {
      // Only update the tags of the last message.
      messageIds = messages.map((t) => t.asThread().messages.slice(-1)[0]);
    } else {
      messageIds = messages.map((m) => m.id);
    }
    await store.messageStore.addTags(messageIds, tag);

    setUpdating(false);
    toast.success(`${messages.length}件に「${tag.name}」を設定しました`);
    logEvent(eventNames.attach_tag);
    store.checkedMessages = [];
  };

  const updateAssignee = async (assignee: User) => {
    setUpdating(true);

    const value = assignee?.id || null;
    const assigneeName = assignee?.name || '担当者未設定';

    // すでに同一ユーザーがアサインされている場合、更新しない
    const messages = store.checkedMessages.filter((m) => m.assignee !== value);

    let messagesData: { teamId: string; messageId: string }[];
    if (store.isInThreadView) {
      // Only update the assignee of the last message.
      messagesData = messages.map((t) => ({
        teamId: t.teamId,
        messageId: t.asThread().messages.slice(-1)[0],
      }));
    } else {
      messagesData = messages.map((m) => ({
        teamId: m.teamId,
        messageId: m.id,
      }));
    }
    await store.messageStore.updateAssignees(messagesData, assignee);

    setUpdating(false);
    toast.success(
      `${messages.length}件の担当者を「${assigneeName}」に設定しました`
    );
    store.checkedMessages = [];
  };

  const downloadThreadsAsZip = async () => {
    const messageIds: string[] = [];
    const teamIds: string[] = [];
    store.checkedMessages
      .map((m) => m.asThread())
      .forEach((x) => {
        teamIds.push(x.teamId);
        messageIds.push(...x.messages);
      });
    const length = store.checkedMessages.length;
    toast.info(`${length}件のダウンロードを開始しました`);
    try {
      const blob = await store.messageStore.downloadAsZip(
        [...uniq(teamIds)],
        messageIds
      );
      downloadBlob('eml.zip', blob);
      logEvent(eventNames.batch_download_eml);
      toast.success(`${length}件のダウンロードを完了しました`);
    } catch (e) {
      toast.error('ダウンロードに失敗しました');
      console.error('CheckOptions.downloadAsZip:', e);
    }
  };

  const downloadAsZip = async () => {
    if (store.isInThreadView) {
      return downloadThreadsAsZip();
    }

    const getNameContentPairsFrom = async (
      urlAndFilenames: UrlAndFilename[]
    ): Promise<NameContentPair[]> => {
      return await Promise.all(
        urlAndFilenames.map(async (urlAndFilename) => {
          const response = await fetch(urlAndFilename.url);
          const content = await response.blob();
          return { name: urlAndFilename.filename, content };
        })
      );
    };

    const generateZipBlob = (
      nameContentPairs: NameContentPair[],
      name: string
    ) => {
      const zip = new JSZip();

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const folder = zip.folder(restrictFileName(name))!;

      nameContentPairs.forEach((nameContentPair) => {
        const name = restrictFileName(nameContentPair.name);
        const content = nameContentPair.content;
        folder.file(name, content);
      });

      return zip.generateAsync({ type: 'blob' }); // デフォルトで無圧縮
    };

    const saveBlob = (blob: Blob, name: string) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = restrictFileName(name) + '.zip';

      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    /**
     * Windows のファイル名に使用できない文字をエスケープ
     * Mac や Linux より Windows の方がファイル名の制限が厳しいため、Windows に合わせる
     */
    const restrictFileName = (name: string) =>
      name.replace(/[\\/:*?"<>|]/g, (c) => '%' + c.charCodeAt(0).toString(16));

    try {
      const name = 'eml';

      const canDownloadMessages = store.checkedMessages
        .map((m) => m.asMessage())
        .filter((m) => m.emlStoragePath);
      toast.info(`${canDownloadMessages.length}件のダウンロードを開始しました`);

      const sentUrlAndFilenames: UrlAndFilename[] = (
        await Promise.all(
          canDownloadMessages.map(async (m) => {
            const docs = await fetchQueryInArray(
              db
                .collection(`/companies/${store.signInCompany}/sent`)
                .where('inReplyToMessageId', '==', m.id),
              'teamId',
              store.joinedTeamIds
            );
            const canDownloadSent = docs.filter(
              (sent) => sent.data().emlStoragePath
            );
            // [{url: "url", filename: ""}, ...]
            return await Promise.all(
              canDownloadSent.map(async (sent) => {
                const ref = firebase.storage().ref(sent.data().emlStoragePath);
                const url = await ref.getDownloadURL();
                const subject =
                  sent.data().subject.length > 0
                    ? sent.data().subject
                    : 'no_subject';
                return { url, filename: `${subject.substring(0, 70)}.eml` };
              })
            );
          })
        )
      ).flat();

      const messageUrlAndFilenames: UrlAndFilename[] = await Promise.all(
        canDownloadMessages.map(async (m) => {
          const ref = firebase.storage().ref(m.emlStoragePath);
          const url = await ref.getDownloadURL();
          const subject = m.subject.length > 0 ? m.subject : 'no_subject';
          return { url, filename: `${subject.substring(0, 70)}.eml` };
        })
      );

      let urlAndFilenames: UrlAndFilename[] = [
        ...messageUrlAndFilenames,
        ...sentUrlAndFilenames,
      ];

      urlAndFilenames = urlAndFilenames.reduce(
        (previousValue: UrlAndFilename[], currentValue) => {
          let num = 0;

          // splitExt('img.jpg');  // => [ 'img',   'jpg'  ]
          // splitExt('.html');    // => [ '',      'html' ]
          // splitExt('lib.d.ts'); // => [ 'lib.d', 'ts'   ]
          // splitExt('abc');      // => [ 'abc'           ]
          const splitExt = (filename: string) => filename.split(/\.(?=[^.]+$)/);
          const hasSameFilename = (filename: string) =>
            previousValue.some((pv) => pv.filename === filename);
          const generateUniqueFilename = (filename: string): string => {
            if (!hasSameFilename(filename)) return filename;
            num++;
            const split = splitExt(currentValue.filename); // "file.eml"
            return generateUniqueFilename(
              split[0] + '_' + num + '.' + split[1]
            );
          };
          return [
            ...previousValue,
            {
              ...currentValue,
              filename: generateUniqueFilename(currentValue.filename),
            },
          ];
        },
        []
      );

      const zipBlob = await generateZipBlob(
        await getNameContentPairsFrom(urlAndFilenames),
        name
      );
      saveBlob(zipBlob, name);
      logEvent(eventNames.batch_download_eml);
      toast.success(
        `${canDownloadMessages.length}件のダウンロードを完了しました`
      );
    } catch (e) {
      toast.error('ダウンロードに失敗しました');
      Sentry.captureMessage(e as never);
      console.error('CheckOptions.downloadAsZip:', e);
    }
  };

  const toMessageListLink = () => {
    const assignedMe = location.pathname.startsWith('/me/assigned');
    if (assignedMe) return `/me/assigned`;
    if (teamId && inboxId && tagId) {
      if (isDeleted())
        return `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/deleted`;
      return `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/messages`;
    }
    if (tagId) {
      if (isDeleted()) return `/teams/${teamId}/tags/${tagId}/deleted`;
      return `/teams/${teamId}/tags/${tagId}/messages`;
    }
    if (inboxId) {
      if (isDeleted()) return `/teams/${teamId}/inboxes/${inboxId}/deleted`;
      return `/teams/${teamId}/inboxes/${inboxId}/messages`;
    }
    return `/teams/${teamId}/messages`;
  };

  const removeCheckedMessages = async () => {
    const { checkedMessages } = store;
    confirm({
      title: ` ${checkedMessages.length}件の選択中のメールを削除しますか？`,
      description: (
        <div>
          <p>
            一度削除すると元に戻せません。
            <br />
            下記のコンテンツが削除されます。
          </p>
          <Card title={'削除対象のコンテンツ'}>
            ・選択中のメール
            <br />
            ・選択中のメールへの返信中の下書き
            <br />
            ・選択中のメールのコメント
            <br />
            ・選択中のメール内のイベント（例：〇〇が返信を開始しました。）
            <br />
          </Card>
        </div>
      ),
      onOk: async () => {
        history.push(toMessageListLink());
        setRemoving(true);
        const messageIds = getAllMessageIds(checkedMessages);
        await store.messageStore.delete(messageIds);
        setRemoving(false);
        toast.success(`${checkedMessages.length}件を削除しました`);
        logEvent(eventNames.batch_remove_messages);
        store.checkedMessages = [];
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
    });
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      // すべて選択する
      store.checkedMessages = messages;
      return;
    }
    // すべて選択を解除する
    store.checkedMessages = [];
  };

  const isAllChecked = messages.every((sm) =>
    store.checkedMessages.some((cm) => cm.id === sm.id)
  );

  const isDeleted = () => isDeletedPaneLocation(location);

  const getAllMessageIds = (checkedMessages: MessageLike[]) => {
    if (store.isInThreadView) {
      return checkedMessages.map((t) => t.asThread().messages).flat();
    } else {
      return checkedMessages.map((m) => m.id);
    }
  };

  const updateMessagesAsDeleted = async (checkedMessages: MessageLike[]) => {
    toast.destroy();

    const messageIds = getAllMessageIds(checkedMessages);
    await store.messageStore.markAsDeleted(messageIds, true);
    store.checkedMessages = [];

    toast.success(
      <>
        {checkedMessages.length}件のメールをゴミ箱に移動しました
        <Button
          type="link"
          onClick={() => restoreDeletedMessages(checkedMessages)}
        >
          取り消す
        </Button>
      </>
    );
  };

  const restoreDeletedMessages = async (checkedMessages: MessageLike[]) => {
    toast.destroy();

    const messageIds = getAllMessageIds(checkedMessages);
    await store.messageStore.markAsDeleted(messageIds, false);
    store.checkedMessages = [];
    toast.success(
      <>
        ゴミ箱のメール{checkedMessages.length}件を元に戻しました
        <Button
          type="link"
          onClick={() => updateMessagesAsDeleted(checkedMessages)}
        >
          取り消す
        </Button>
      </>
    );
  };

  /**
   * ゴミ箱のメニュー
   */
  const deletedTagMenu = () => {
    return (
      <Menu>
        <Menu.Item
          key="4"
          onClick={() => restoreDeletedMessages(store.checkedMessages)}
        >
          元に戻す
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={removeCheckedMessages}
          disabled={!canDeleteMessage}
        >
          完全に削除する
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() =>
            setTimeout(() => {
              markDeletingMessages();
            }, 200)
          }
          disabled={!canDeleteMessage}
        >
          すべてのメールを完全に削除
        </Menu.Item>
      </Menu>
    );
  };

  const inSearch = store.searchStore.inSearch;
  const markDeletingMessages = () => {
    let params: SearchParams;
    if (inSearch) {
      params = {
        inSearch,
        ...store.searchStore.getSearchParams(0, isDeleted()),
      } as SearchParams;
    } else {
      const status = getStatusesFromView(
        store.messageFilterStore.view,
        allTeamStatusesInGroup
      );
      const deleted = isDeleted();

      let tags: string[] = [];
      if (tagId && !deleted) {
        const tag = store.getTag(tagId);
        const allTab = !status;
        if (!tag?.isDefault || !allTab) {
          tags = [tagId];
        }
      }
      params = {
        inSearch,
        companyId: company.id,
        teamIds: teamId ? [teamId] : joinedTeamIds,
        tags,
        inboxId,
        deleted,
      };
      const assignedMe = location.pathname.startsWith('/me/assigned');
      if (assignedMe) {
        params.assignee = me.id;
      }
      if (store.messageFilterStore.view === 'other') {
        params.customStatusIds = Object.values(allTeamStatusesInGroup).flatMap(
          (statuses) => statuses.map((status) => status.id)
        );
      } else if (status) {
        params.status =
          status.statusType === MessageStatus.CustomStatus
            ? status.customStatusId!
            : status.statusType;
      }
    }
    const key = v4();
    toast.loading({
      content: 'メールを読み込み中です...',
      duration: 0,
      key: key,
    });
    markDeletingMessagesFunction(params)
      .then((res) => {
        toast.success({
          content: 'メールの読み込みが完了しました',
          key,
        });
        const count = res.data.count;
        confirm({
          title: ` ${count}件のメールを削除しますか？`,
          description: (
            <div>
              <p>
                一度削除すると元に戻せません。
                <br />
                下記のコンテンツが削除されます。
              </p>
              <Card title={'削除対象のコンテンツ'}>
                ・選択中のメール
                <br />
                ・選択中のメールへの返信中の下書き
                <br />
                ・選択中のメールのコメント
                <br />
                ・選択中のメール内のイベント（例：〇〇が返信を開始しました。）
                <br />
              </Card>
            </div>
          ),
          onOk: async () => {
            history.push(toMessageListLink());
            setRemoving(true);
            const deletionId = res.data.deletionId;
            await deleteMessagesFunction({
              companyId: company.id,
              deletionId,
            });
            setRemoving(false);
            toast.success(`${count}件のメールを削除しました`);
            logEvent(eventNames.batch_remove_messages);
            store.checkedMessages = [];
          },
          okText: '削除',
          cancelText: 'キャンセル',
          okType: 'danger',
        });
      })
      .catch((e) => {
        const error = e as { code?: string };
        if (error.code) {
          if (error.code === 'functions/invalid-argument') {
            toast.success({
              content: 'メールの読み込みが完了しました',
              key,
            });
            confirm({
              title: '条件に一致するメールが見つかりませんでした。',
              description: '画面をリロードして再度お試しください。',
            });
            return;
          }
          if (error.code === 'functions/out-of-range') {
            toast.success({
              content: 'メールの読み込みが完了しました',
              key,
            });
            confirm({
              title: 'メールを削除できませんでした。',
              description: (
                <>
                  <p>
                    対象となるメールが5000件を超えているため削除できませんでした。
                  </p>
                  <p>検索条件を変更して再度お試しください。</p>
                </>
              ),
            });
            return;
          }
        }
        toast.error({
          content: 'メールの読み込みに失敗しました。',
          key,
        });
        console.error(e);
      });
  };

  const shouldRenderProcessAllButton =
    messages.length > 0 &&
    store.selectedStatus === '未対応' &&
    !messages[0].deleted &&
    !inSearch;
  return (
    <div className="flex items-center justify-between bg-sumi-50 px-3 py-2">
      <div className="flex items-center gap-2">
        <Checkbox
          indeterminate={store.checkedMessages.length > 0 && !isAllChecked}
          checked={isAllChecked}
          onChange={onCheckAllChange}
          disabled={isUpdating || isRemoving}
        />
        <span>{store.checkedMessages.length}件を選択中</span>
      </div>
      <Dropdown
        overlay={
          isDeleted() ? (
            deletedTagMenu()
          ) : (
            <Menu>
              {(store.selectedStatus !== '未対応' || inSearch) && (
                <Menu.Item key="1" onClick={() => updateStatus('未対応')}>
                  未対応にする
                </Menu.Item>
              )}
              {(store.selectedStatus !== '対応済み' || inSearch) && (
                <Menu.Item key="2" onClick={() => updateStatus('対応済み')}>
                  対応済みにする
                </Menu.Item>
              )}

              {match.params.teamId && (
                <TagSubOptions
                  tags={store.getTags(match.params.teamId)}
                  onClickTag={(tag: Tag) => updateTag(tag)}
                />
              )}
              {match.params.teamId && (
                <AssigneeSubOptions
                  users={store.users}
                  teamMembers={store.getUsersByTeamId(match.params.teamId)}
                  onClickAssignee={(assignee: User) => updateAssignee(assignee)}
                />
              )}
              <Menu.Item key="3" onClick={downloadAsZip}>
                ダウンロードする
              </Menu.Item>
              <Menu.Item
                key="4"
                onClick={() => updateMessagesAsDeleted(store.checkedMessages)}
                aria-label="ゴミ箱に移動する"
                disabled={!canDeleteMessage}
              >
                ゴミ箱に移動する
              </Menu.Item>
              <Menu.Divider />
              {shouldRenderProcessAllButton && (
                <Menu.Item
                  onClick={() =>
                    setTimeout(() => {
                      updateAllToProcessed();
                    }, 200)
                  }
                >
                  すべての未対応を対応済みにする
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() =>
                  setTimeout(() => {
                    markDeletingMessages();
                  }, 200)
                }
                disabled={!canDeleteMessage}
              >
                すべてのメールを完全に削除
              </Menu.Item>
            </Menu>
          )
        }
        trigger={['click']}
      >
        {isUpdating || isRemoving ? (
          <Loading className="pointer-events-none block h-[16px] w-[16px] text-sea-500" />
        ) : (
          <button
            aria-label="message list dropdown"
            className="flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full bg-transparent p-0 hover:bg-sumi-100"
          >
            <Icon icon={CaretDown} size={20} />
          </button>
        )}
      </Dropdown>
    </div>
  );
});
