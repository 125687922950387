import { Select, SelectProps } from '../../../forms';
import { Role, ROLE_DEFINITIONS, ROLES } from 'lib';
import { useCallback } from 'react';

type Props = Omit<SelectProps<Role>, 'options'>;

const OPTIONS: SelectProps<Role>['options'] = ROLES.map((role) => ({
  value: role,
  label: ROLE_DEFINITIONS[role].name,
}));

const DEFAULT_VARIANTS = { width: 'full', size: 'lg', rounded: 'lg' } as const;

export const RoleSelect = (props: Props) => {
  const renderOption = useCallback((role: Role, label: string) => {
    return (
      <div className="w-full">
        <div className="mb-1">{label}</div>
        <div className="whitespace-normal break-all text-xs text-sumi-600">
          {ROLE_DEFINITIONS[role].description}
        </div>
      </div>
    );
  }, []);
  return (
    <Select
      {...props}
      options={OPTIONS}
      variants={props.variants ?? DEFAULT_VARIANTS}
      renderOption={renderOption}
      dropdownClassName="w-[var(--radix-popper-anchor-width)]"
    />
  );
};
