import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';
import { Role } from 'lib';

export type InvitedUserData = {
  email: string;
  role: Role;
  invitationId: string;
  expiredAt: Timestamp;
};

export const createInvitedUser = createEntityFactory(
  'InvitedUser',
  (data: InvitedUserData) => ({
    get isExpired() {
      return data.expiredAt.toDate() < new Date();
    },
  })
);

export type InvitedUser = ReturnType<typeof createInvitedUser>;

export const invitedUserConverter = createConverter(createInvitedUser);
