import React, {
  ComponentProps,
  ComponentPropsWithoutRef,
  createContext,
  FC,
  forwardRef,
  useCallback,
  useContext,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { SortArrows } from '../SortArrows/SortArrows';
import { Link, useHistory } from 'react-router-dom';

type RootProps = ComponentPropsWithoutRef<'table'>;

type TheadProps = ComponentPropsWithoutRef<'thead'>;

type TbodyProps = ComponentPropsWithoutRef<'tbody'>;

type TrProps = ComponentPropsWithoutRef<'tr'> & {
  to?: string;
};

type ThProps = ComponentPropsWithoutRef<'th'> & {
  sortable?: boolean;
  sortDir?: 'asc' | 'desc' | null;
};

type TdProps = ComponentProps<'th'> & {
  withAnchor?: boolean;
};

const Thead: FC<TheadProps> = ({ children, ...props }) => {
  return <thead {...props}>{children}</thead>;
};

const Tbody: FC<TbodyProps> = ({ children, ...props }) => {
  return <tbody {...props}>{children}</tbody>;
};

const tr = tv({
  base: 'border-b border-b-sumi-300 [tbody_&]:last:border-b-transparent [tbody_&]:hover:bg-sumi-50',
  variants: {
    interactive: {
      true: '[tbody_&]:cursor-pointer',
      false: '',
    },
  },
  defaultVariants: {
    interactive: false,
  },
});

const TrContext = createContext<TrProps['to']>(undefined);

const Tr = forwardRef<HTMLTableRowElement, TrProps>(
  ({ className, children, to, ...props }, ref) => {
    const history = useHistory();
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLTableRowElement>) => {
        props.onClick?.(e);
        if (to && !e.defaultPrevented) {
          const target = e.target;
          if (!target || !(target instanceof Element)) {
            return;
          }

          if (
            target instanceof HTMLAnchorElement ||
            target instanceof HTMLButtonElement ||
            target.closest('a') ||
            target.closest('button')
          ) {
            return;
          }

          history.push(to);
        }
      },
      [to, props.onClick]
    );
    return (
      <tr
        className={tr({ interactive: !!to, className })}
        onClick={handleClick}
        {...props}
        ref={ref}
      >
        <TrContext.Provider value={to}>{children}</TrContext.Provider>
      </tr>
    );
  }
);

Tr.displayName = 'Tr';

const Th: FC<ThProps> = ({
  className,
  children,
  onClick,
  sortable,
  sortDir = null,
  ...props
}) => {
  return (
    <th
      className={twMerge(
        'font-normal',
        onClick ? 'cursor-pointer' : '',
        className
      )}
      onClick={onClick}
      {...props}
    >
      <div className="grid h-[40px] select-none grid-cols-[auto_1fr] items-center gap-2 whitespace-nowrap px-2 py-1">
        {children}
        {sortable && <SortArrows dir={sortDir} className="h-[20px] w-[20px]" />}
      </div>
    </th>
  );
};

const Td = forwardRef<HTMLTableCellElement, TdProps>(
  ({ className, children, withAnchor, ...props }, ref) => {
    const to = useContext(TrContext);
    return (
      <td
        className={twMerge(
          'px-2 py-1 before:inline-block before:min-h-[calc(40px_-_theme(spacing.1)_*_2_-_1px)] before:align-middle before:content-[""]',
          className
        )}
        {...props}
        ref={ref}
      >
        {withAnchor && to ? (
          <Link
            to={to}
            className="inline-flex h-full w-full items-center rounded align-middle text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-500/50"
          >
            {children}
          </Link>
        ) : (
          <span className="inline-block align-middle">{children}</span>
        )}
      </td>
    );
  }
);

Td.displayName = 'Td';

type Component = FC<RootProps> & {
  Thead: typeof Thead;
  Tbody: typeof Tbody;
  Tr: typeof Tr;
  Th: typeof Th;
  Td: typeof Td;
};

export const Table: Component = ({ children, className, ...props }) => {
  return (
    <table className={twMerge('text-sm', className)} {...props}>
      {children}
    </table>
  );
};

Table.Thead = Thead;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Th = Th;
Table.Td = Td;
