import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { orderBy, query } from 'firebase/firestore';
import { invitedUserConverter } from '../../firestore/entity/invitedUser';
import { atom } from 'jotai';
import { signInCompanyStripeProductAtom } from './signInCompanyStripeProduct';
import { usersAtom } from './user';

export const [invitedUsersLoadingAtom, invitedUsersAtom] =
  atomWithSubscribeCollection(
    () =>
      query(
        companyCollection('invitedUsers', invitedUserConverter),
        orderBy('email')
      ),
    (snapshot) => snapshot.docs.map((doc) => doc.data())
  );

export const invitableAtom = atom((get) => {
  const product = get(signInCompanyStripeProductAtom);
  if (!product) {
    return false;
  }

  const maxUsers = product.maxUsers;
  if (!maxUsers) {
    return true;
  }

  const users = get(usersAtom);
  const invitedUsers = get(invitedUsersAtom);
  console.log({ maxUsers, users, invitedUsers });
  return maxUsers > users.length + invitedUsers.length;
});
