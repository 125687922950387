import { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { Card, Dropdown, Menu, Modal, Tooltip } from 'antd';
import { PCOrTabletOnly, SPOnly } from '../../../../Common/MediaQuery';
import firebase from 'firebase.js';
import { downloadFromUrl } from '../../../../../util';
import { eventNames, logEvent } from '../../../../../analytics';
import { useHistory } from 'react-router-dom';
import { extractNonInlineSentAttachments, Sent, User } from 'lib';
import { SeenDropdown } from './SeenDropdown';
import { MessageCollapse } from 'components/Message';
import SentHeaderContact from './SentHeaderContact';
import { IconButton } from 'components/basics';
import SentBody from './SentBody';
import { More, Reply, ReplyAll, SendOn } from 'components/icons';
import { deleteDoc } from 'firebase/firestore';
import { Attachments } from '../Message/Attachments';

const { confirm } = Modal;

const downloadEml = async (sent: Sent) => {
  const ref = firebase.storage().ref(sent.emlStoragePath);
  const url = await ref.getDownloadURL();
  const subject = (sent.subject || '').length > 0 ? sent.subject : 'no_subject';
  downloadFromUrl(url, `${subject}.eml`);
  logEvent(eventNames.download_sent_eml);
};

const openPrint = async (sent: Sent) => {
  window.open(`/print/sent/${sent.id}`);
};

type Props = {
  sent: Sent;
  startReply: (sent: Sent, replyAll: boolean) => void;
  startForwarding: (sent: Sent) => void;
  scrollToAnchorSent: () => void;
  scrollToBottom: () => void;
  lock: any;
  me: User;
  getUser: any;
  highlight: boolean;
  collapsed: boolean;
  onLoad?: (id: string) => void;
  isReadOnly?: boolean;
  canDeleteMessage?: boolean;
};

const ConversationSentIndex = ({
  sent,
  startReply,
  startForwarding,
  scrollToAnchorSent,
  scrollToBottom,
  lock,
  getUser,
  highlight,
  collapsed: defaultCollapsed,
  onLoad,
  isReadOnly = false,
  canDeleteMessage = false,
}: Props) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    if (!defaultCollapsed) {
      setCollapsed(defaultCollapsed);
    }
  }, [defaultCollapsed]);

  useEffect(() => {
    if (highlight) {
      setTimeout(() => scrollToAnchorSent(), 1000);
    }
  }, [highlight, scrollToAnchorSent]);
  const history = useHistory();
  const locked = Boolean(lock);
  const sender = getUser?.(sent.sender);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const remove = async () => {
    confirm({
      title: `送信済みのメール "${sent.subject}"（件名） を削除しますか？`,
      content: (
        <div>
          <p>
            一度削除すると元に戻せません。
            <br />
            下記のコンテンツが削除されます。
          </p>
          <Card title={'削除対象のコンテンツ'}>
            ・送信済みのメール（添付ファイルを含む）
            <br />
            ・送信済みのメールへの返信中の下書き
            <br />
          </Card>
        </div>
      ),
      onOk: async () => {
        history.push('./');
        await deleteDoc(sent.ref);
        logEvent(eventNames.remove_sent);
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  const highLightStyles = {
    borderColor: 'rgba(53, 119, 239, 0.2)',
  };

  const timestamp = sent.date.format('YYYY年M月D日 HH:mm');
  const timestampShort = sent.date.format('YYYY年M月D日');
  return (
    <div
      className="my-4 box-border w-full rounded-xl border-4 border-transparent bg-white shadow-card"
      style={highlight ? { ...highLightStyles } : {}}
      data-testid="sentCard"
    >
      <Element name={`sent-${sent.id}`} />
      <div className="px-3 pt-2">
        <MessageCollapse collapsed={collapsed} onClick={toggleCollapsed} />
        <SPOnly>
          <div className="pb-2">
            <div className="mb-2 flex items-center justify-end">
              <p className="m-0 mr-2 text-sm text-sumi-600">{timestamp}</p>
              {!locked && (
                <Tooltip title="全員に返信" mouseEnterDelay={0.5}>
                  <IconButton
                    color="sumi"
                    onClick={() => startReply(sent, true)}
                    component={ReplyAll}
                    disabled={isReadOnly}
                  />
                </Tooltip>
              )}
              <SeenDropdown teamId={sent.teamId} openEvents={sent.openEvents} />
            </div>
            <SentHeaderContact sender={sender} sent={sent} />
          </div>
        </SPOnly>

        <PCOrTabletOnly>
          <div className="flex items-start justify-between gap-2 pb-6">
            <SentHeaderContact sender={sender} sent={sent} />
            <div className="flex items-center gap-2 text-xs">
              <p className="m-0 whitespace-nowrap text-sumi-600">
                <span className={'hidden xl:inline'}>{timestamp}</span>
                <Tooltip title={timestamp} mouseEnterDelay={0.5}>
                  <span className={'inline xl:hidden'}>{timestampShort}</span>
                </Tooltip>
              </p>
              {!locked && (
                <Tooltip title="全員に返信" mouseEnterDelay={0.5}>
                  <IconButton
                    color="sumi"
                    onClick={() => startReply(sent, true)}
                    component={ReplyAll}
                    disabled={isReadOnly}
                  />
                </Tooltip>
              )}
              {!locked && (
                <Tooltip title="返信" mouseEnterDelay={0.5}>
                  <IconButton
                    color="sumi"
                    onClick={() => startReply(sent, false)}
                    component={Reply}
                    disabled={isReadOnly}
                  />
                </Tooltip>
              )}
              {
                <Tooltip title="転送" mouseEnterDelay={0.5}>
                  <IconButton
                    color="sumi"
                    onClick={() => startForwarding(sent)}
                    component={SendOn}
                    disabled={isReadOnly}
                  />
                </Tooltip>
              }
              <SeenDropdown teamId={sent.teamId} openEvents={sent.openEvents} />
              {sent.emlStoragePath && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        data-testid="download-email"
                        onClick={() => downloadEml(sent)}
                      >
                        ダウンロードする
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        data-testid="print"
                        onClick={() => openPrint(sent)}
                      >
                        印刷する
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        data-testid="move-to-trash"
                        onClick={remove}
                        disabled={isReadOnly || !canDeleteMessage}
                      >
                        ゴミ箱に移動する
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <IconButton
                    color="sumi"
                    component={More}
                    aria-label="メッセージメニュー"
                  />
                </Dropdown>
              )}
            </div>
          </div>
        </PCOrTabletOnly>

        {collapsed && (
          <>
            <Attachments
              attachments={extractNonInlineSentAttachments(sent.attachments)}
            />
            <div className="pt-3 lg:p-0"></div>
          </>
        )}
      </div>
      {!collapsed && (
        <SentBody
          lock={lock}
          sent={sent}
          scrollToBottom={scrollToBottom}
          attachments={extractNonInlineSentAttachments(sent.attachments)}
          startReplyAll={() => startReply(sent, true)}
          startReply={() => startReply(sent, false)}
          startForwarding={() => startForwarding(sent)}
          onLoad={onLoad}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
};

export default ConversationSentIndex;
