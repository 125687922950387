import React, { ComponentProps, forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { Loading } from './Loading';
import { Icon } from './Icon';
import * as Slot from '@radix-ui/react-slot';

type IconWithSize = {
  component: ComponentProps<typeof Icon>['icon'];
  size: number | string;
};

export type ButtonProps = {
  color?: 'primary' | 'sumi' | 'danger';
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'md' | 'sm';
  disabled?: boolean;
  loading?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'] | IconWithSize;
  asChild?: boolean;
} & React.ComponentProps<'button'>;

export const variants = tv({
  base: 'inline-grid cursor-pointer select-none items-center whitespace-nowrap rounded font-bold ease-linear [--color:var(--base-color)] hover:[--color:var(--hover-color)] active:[--color:var(--active-color)] data-[disabled=true]:cursor-not-allowed',
  variants: {
    color: {
      primary:
        '[--active-color:theme(colors.sea.700)] [--base-color:theme(colors.sea.500)] [--hover-color:theme(colors.sea.600)] [--text-color:white]',
      sumi: '[--text-color:white] [--active-color:theme(colors.sumi.700)] [--base-color:theme(colors.sumi.500)] [--hover-color:theme(colors.sumi.600)]',
      danger:
        '[--text-color:white] [--active-color:theme(colors.sun.700)] [--base-color:theme(colors.sun.500)] [--hover-color:theme(colors.sun.600)]',
    },
    style: {
      contained:
        'bg-[var(--color)] text-[var(--text-color)] data-[disabled=true]:bg-sumi-400 data-[disabled=true]:text-white',
      outlined:
        'border border-[var(--color)] bg-transparent text-[var(--color)] data-[disabled=true]:border-sumi-400 data-[disabled=true]:text-sumi-400',
      text: 'bg-transparent text-[var(--color)] underline data-[disabled=true]:text-sumi-400',
    },
    loading: {
      true: 'data-[disabled=true]:cursor-wait',
    },
    hasIcon: {
      true: 'grid grid-cols-[auto_1fr] items-center gap-1.5',
    },
    size: {
      md: 'h-10 px-2 text-sm sm:px-3',
      sm: 'h-8 px-1.5 text-xs sm:px-2',
    },
  },
  defaultVariants: {
    color: 'primary',
    style: 'contained',
    size: 'md',
  },
});

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled,
      color,
      variant,
      loading,
      size,
      icon,
      className,
      children,
      style,
      asChild,
      ...otherProps
    },
    ref
  ) => {
    const Comp = asChild ? Slot.Root : 'button';
    const normalizedIcon = icon ? normalizeIcon(icon) : undefined;
    const iconSize = cssSize(normalizedIcon?.size ?? 20);
    return (
      <Comp
        type="button"
        className={variants({
          style: variant,
          color,
          size,
          loading,
          hasIcon: loading || !!icon,
          className,
        })}
        style={{ ...style, ['--icon-size' as never]: iconSize }}
        disabled={disabled || loading}
        data-disabled={disabled || loading}
        {...otherProps}
        ref={ref}
      >
        {loading && (
          <div className="flex h-[var(--icon-size)] w-[var(--icon-size)] items-center justify-center">
            <Loading />
          </div>
        )}
        {!loading && normalizedIcon && (
          <Icon icon={normalizedIcon.component} size={normalizedIcon.size} />
        )}
        <Slot.Slottable>{children}</Slot.Slottable>
      </Comp>
    );
  }
);

const normalizeIcon = (
  icon: NonNullable<ButtonProps['icon']>
): IconWithSize => {
  const keys = Object.keys(icon);
  if (keys.includes('component') && keys.includes('size')) {
    return icon as IconWithSize;
  } else {
    return {
      component: icon as ComponentProps<typeof Icon>['icon'],
      size: 20,
    };
  }
};

const cssSize = (size: number | string): string => {
  return typeof size === 'number' ? `${size}px` : size;
};

Button.displayName = 'Button';

export default Button;
