import { Table } from '../../../table/Table/Table';
import { Role, ROLE_DEFINITIONS } from 'lib';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { MemberItem } from '../../misc/MemberItem/MemberItem';
import moment from 'moment';
import { Icon } from '../../../basics';
import { Delete } from '../../../icons';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';

type InvitedMember = {
  id: string;
  email: string;
  role: Role;
  expiresAt: Date;
};

type Props = {
  invitedMembers: InvitedMember[];
  onDelete: (id: string) => Promise<void>;
  canDelete: boolean;
};

const columns: ColumnDef<InvitedMember>[] = [
  {
    accessorKey: 'email',
    header: 'メールアドレス',
    cell: (info) => {
      const data = info.row.original;
      const expiresAt = moment(data.expiresAt);
      const isExpired = moment().isAfter(expiresAt);
      const text = `招待の期限：${expiresAt.format('YYYY/MM/DD HH:mm')} ${
        isExpired ? '（期限切れ）' : ''
      }`;
      return (
        <div className="py-2">
          <MemberItem
            name={data.email}
            email={text}
            avatar={{ name: data.email }}
            isMe={false}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'role',
    header: '役割',
    cell: (info) => {
      const role = info.getValue() as Role;
      return ROLE_DEFINITIONS[role].name;
    },
  },
];

export const InvitedMembersTable = ({
  invitedMembers,
  onDelete,
  canDelete,
}: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const showDialog = useConfirmDialog();
  const table = useReactTable({
    columns,
    data: invitedMembers,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <div className="w-[900px] min-w-[600px] max-w-full">
      <Table className="w-full">
        <Table.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    sortable={header.column.getCanSort()}
                    sortDir={header.column.getIsSorted() || null}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.Th>
                );
              })}
              {/* 削除ボタン用のスペース */}
              {canDelete && <Table.Th />}
            </Table.Tr>
          ))}
        </Table.Thead>
        <Table.Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Table.Tr key={row.id} className="group">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Td>
                  );
                })}
                {canDelete && (
                  <Table.Td className="w-10 p-0">
                    <button
                      type="button"
                      className="invisible m-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded bg-transparent p-0 text-red-500 hover:bg-red-500/10 group-hover:visible"
                      aria-label="下書きを削除"
                      onClick={() => {
                        showDialog({
                          title: '招待を削除しますか？',
                          description: (
                            <>
                              "{row.original.email}" の招待を削除しますか？
                              <br />
                              一度削除するともとに戻せません
                            </>
                          ),
                          okText: '削除',
                          okType: 'danger',
                          onOk: () => onDelete(row.original.id),
                        });
                      }}
                    >
                      <Icon icon={Delete} size={20} />
                    </button>
                  </Table.Td>
                )}
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </div>
  );
};
