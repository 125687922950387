import { TabsSettingPageContainer } from '../../../App/Settings/common/TabsSettingPageContainer/TabsSettingPageContainer';
import React, { memo } from 'react';
import { CompanyMembersWithLogic } from '../companyMembers/CompanyMembers/CompanyMembersWithLogic';
import { Groups } from '../../../App/Settings/MembersAndGroups/Groups';

export const CompanyMembersAndGroupsWithLogic = memo(
  function CompanyMembersAndGroupsWithLogic() {
    return (
      <TabsSettingPageContainer
        title="メンバーと権限"
        pages={[
          {
            pathname: '/settings/company/members',
            label: 'メンバー',
            element: () => <CompanyMembersWithLogic />,
          },
          {
            pathname: '/settings/company/groups',
            label: 'グループ',
            element: () => <Groups />,
          },
        ]}
      />
    );
  }
);
