import { ComponentProps, useMemo, useState } from 'react';
import { Avatar } from '../../../basics/Avatar/Avatar';
import { Role, ROLE_DEFINITIONS } from 'lib';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { MemberItem } from '../../misc/MemberItem/MemberItem';
import { Table } from '../../../table/Table/Table';
import { Icon } from '../../../basics';
import { Info } from '../../../icons';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import moment from 'moment/moment';

type Member = {
  id: string;
  name: string;
  email: string;
  avatar: Omit<ComponentProps<typeof Avatar>, 'size'>;
  isMe: boolean;
  role: Role;
  createdAt: Date;
  lastLoggedInAt: Date | undefined;
};

type Props = {
  members: Member[];
  getHref: (id: string) => string;
  upgraded: boolean;
};

export const CompanyMembersTable = ({ members, getHref, upgraded }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns: ColumnDef<Member>[] = useMemo(() => {
    const columns: ColumnDef<Member>[] = [
      {
        accessorKey: 'name',
        header: '名前',
        cell: (info) => {
          const data = info.row.original;
          return (
            <div className="py-2">
              <MemberItem
                name={data.name}
                email={data.email}
                avatar={data.avatar}
                isMe={data.isMe}
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'role',
        header: '役割',
        cell: (info) => {
          const role = info.getValue() as Role;
          return (
            <div className="flex items-center gap-1">
              <div>{ROLE_DEFINITIONS[role].name}</div>
              <Tooltip content={ROLE_DEFINITIONS[role].description}>
                <div>
                  <Icon icon={Info} size={24} className="block text-sumi-600" />
                </div>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    if (upgraded) {
      columns.push({
        accessorKey: 'lastLoggedInAt',
        header: '最終ログイン',
        cell: (info) => {
          return info.getValue()
            ? moment(info.getValue() as Date).format('YYYY/MM/DD HH:mm')
            : '-';
        },
      });
      columns.push({
        accessorKey: 'createdAt',
        header: '作成日',
        enableSorting: upgraded,
        cell: (info) => {
          return moment(info.getValue() as Date).format('YYYY/MM/DD');
        },
      });
    }
    return columns;
  }, [upgraded]);
  const table = useReactTable({
    columns,
    data: members,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <div className="w-[900px] min-w-[600px] max-w-full">
      <Table className="w-full">
        <Table.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    sortable={header.column.getCanSort()}
                    sortDir={header.column.getIsSorted() || null}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          ))}
        </Table.Thead>
        <Table.Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Table.Tr key={row.id} to={getHref(row.original.id)}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Td
                      key={cell.id}
                      withAnchor={cell.column.id === 'name'}
                      className="whitespace-nowrap"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </div>
  );
};
