import { User } from 'lib';
import { Avatar } from 'components/basics/Avatar/Avatar';
import { ReactNode } from 'react';

type Props = {
  user: User | undefined;
  iconName: string;
  contactElement: ReactNode;
  recipientElement: ReactNode;
};

export const HeaderContactBase = ({
  user,
  iconName,
  contactElement,
  recipientElement,
}: Props) => {
  return (
    <div className="grid w-full min-w-[40px] max-w-full grid-cols-[auto_1fr] items-center gap-2 overflow-hidden">
      <div className="w-[40px]">
        {user ? (
          <Avatar user={user} size={40} />
        ) : (
          <Avatar name={iconName} size={40} />
        )}
      </div>
      <div className="flex flex-col items-start gap-0.5 overflow-hidden">
        {contactElement}
        <div className="max-w-full">{recipientElement}</div>
      </div>
    </div>
  );
};
