import { ComponentProps, useCallback, useMemo } from 'react';
import { SettingPageDrawer } from '../../../../App/Settings/common/SettingPageDrawer/SettingPageDrawer';
import { Role } from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { Input, Options, Select } from '../../../forms';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { RoleSelect } from '../RoleSelect/RoleSelect';
import { Button } from '../../../basics';

type Form = {
  email: string;
  role: Role;
  teamId: string;
};

type Props = Pick<
  ComponentProps<typeof SettingPageDrawer>,
  'open' | 'onOpenChange' | 'container'
> & {
  onSend: (
    form: Form
  ) => Promise<{ success: true } | { success: false; error: string }>;
  teams: { id: string; name: string }[];
};

export const CompanyMemberInviteDrawer = ({
  open,
  onOpenChange,
  container,
  onSend,
  teams,
}: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    defaultValues: {
      email: '',
      role: 'member',
      teamId: '',
    },
  });
  const close = useCallback(() => onOpenChange?.(false), [onOpenChange]);
  const teamOptions: Options<string> = useMemo(() => {
    return [
      { value: '', label: '(選択なし)' },
      ...teams.map((t) => ({ value: t.id, label: t.name })),
    ];
  }, [teams]);
  const onSubmit = useCallback(
    async (form: Form) => {
      const result = await onSend(form);
      if (!result.success) {
        setError('email', { type: 'value', message: result.error });
      }
      close();
    },
    [onSend, setError, close]
  );
  return (
    <SettingPageDrawer
      title="メンバーを招待"
      open={open}
      onOpenChange={close}
      container={container}
    >
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <InputGroup
          label="送信先"
          errorMessage={errors.email?.message}
          required
        >
          <Input
            placeholder="メールアドレス"
            type="email"
            {...register('email', {
              validate: (value) =>
                value.length > 0 || 'メールアドレスを入力してください',
            })}
            disabled={isSubmitting}
            autoFocus
          />
        </InputGroup>
        <InputGroup label="役割">
          <Controller
            render={({ field: { value, onChange } }) => (
              <RoleSelect
                value={value}
                onChange={onChange}
                disabled={isSubmitting}
              />
            )}
            name="role"
            control={control}
          />
        </InputGroup>
        <InputGroup label="チーム">
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                options={teamOptions}
                variants={{ width: 'full', size: 'lg', rounded: 'lg' }}
                disabled={isSubmitting}
              />
            )}
            name="teamId"
            control={control}
          />
        </InputGroup>
        <div>
          <Button type="submit" loading={isSubmitting} className="min-w-[72px]">
            招待
          </Button>
        </div>
      </form>
    </SettingPageDrawer>
  );
};
