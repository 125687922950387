import { CompanyMembers } from './CompanyMembers';
import { ComponentProps, useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../../atoms/auth';
import {
  invitableAtom,
  invitedUsersAtom,
} from '../../../../atoms/firestore/invite';
import { signInCompanyStripeProductAtom } from '../../../../atoms/firestore/signInCompanyStripeProduct';
import { usersAtom } from '../../../../atoms/firestore/user';
import { Role } from 'lib';
import { useToast } from '../../../../hooks/useToast';
import { runTransaction } from 'firebase/firestore';
import { db9 } from '../../../../firebase';
import { companyDoc } from '../../../../firestore';
import { Route, Switch } from 'react-router-dom';
import { CompanyMemberDrawerWithLogic } from '../CompanyMemberDrawer/CompanyMemberDrawerWithLogic';
import { CompanyMemberInviteDrawerWithLogic } from '../CompanyMemberInviteDrawer/CompanyMemberInviteDrawerWithLogic';

export const CompanyMembersWithLogic = () => {
  const me = useAtomValue(meAtom);
  const invitable = useAtomValue(invitableAtom);
  const product = useAtomValue(signInCompanyStripeProductAtom);
  const users = useAtomValue(usersAtom);
  const invitedUsers = useAtomValue(invitedUsersAtom);
  const { showToast } = useToast();

  const members: ComponentProps<typeof CompanyMembers>['members'] =
    useMemo(() => {
      return users.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        avatar: { user },
        isMe: user.id === me.id,
        role: user.role as Role,
        createdAt: user.createdAt.toDate(),
        lastLoggedInAt: user.lastLoggedInAt?.toDate(),
      }));
    }, [users, me.id]);

  const invitedMembers: ComponentProps<
    typeof CompanyMembers
  >['invitedMembers'] = useMemo(() => {
    return invitedUsers.map((user) => ({
      id: user.id,
      email: user.email,
      role: user.role,
      expiresAt: user.expiredAt.toDate(),
    }));
  }, [invitedUsers]);

  const handleDeleteInvite = useCallback(
    async (id: string) => {
      const found = invitedUsers.find((u) => u.id === id);
      if (!found) {
        showToast('error', '招待が見つかりませんでした');
        return;
      }

      await runTransaction(db9, async (tx) => {
        tx.delete(companyDoc('invitedUsers', id));
        tx.delete(companyDoc('invitations', found.invitationId));
      });
    },
    [invitedUsers, showToast]
  );

  return (
    <>
      <CompanyMembers
        invitePageHref="/settings/company/members/invite"
        inviteDisabled={!me.isAdmin || !invitable}
        inviteDisabledReason={getInviteDisabledReason(me.isAdmin, invitable)}
        upgraded={product?.companyMembersPageUpgraded ?? false}
        members={members}
        invitedMembers={invitedMembers}
        onDeleteInvite={handleDeleteInvite}
        getDetailHref={getDetailHref}
        canDeleteInvite={me.isAdmin}
      />
      <Switch>
        <Route
          path="/settings/company/members/invite"
          exact
          component={CompanyMemberInviteDrawerWithLogic}
        />
        <Route
          path="/settings/company/members/:userId"
          exact
          component={CompanyMemberDrawerWithLogic}
        />
      </Switch>
    </>
  );
};

const getInviteDisabledReason = (isAdmin: boolean, invitable: boolean) => {
  if (!isAdmin) {
    return '権限がありません';
  }
  if (!invitable) {
    return '現在のプランのユーザー数（招待中のユーザーも含みます）の上限に達しているため招待できません';
  }
  return undefined;
};

const getDetailHref = (id: string) => {
  return `/settings/company/members/${id}`;
};
