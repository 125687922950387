import { SettingPageDrawer } from '../../../../App/Settings/common/SettingPageDrawer/SettingPageDrawer';
import { ComponentProps, useCallback, useState } from 'react';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { Input } from '../../../forms';
import { Role } from 'lib';
import { Button } from '../../../basics';
import { RoleSelect } from '../RoleSelect/RoleSelect';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';

type Props = Pick<
  ComponentProps<typeof SettingPageDrawer>,
  'open' | 'onOpenChange' | 'container'
> & {
  id: string;
  email: string;
  name: string;
  role: Role;
  onUpdate: (id: string, role: Role) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  readonly: boolean;
};

export const CompanyMemberDrawer = ({
  open,
  onOpenChange,
  container,
  id,
  email,
  name,
  role: initialRole,
  onUpdate,
  onDelete,
  readonly,
}: Props) => {
  const [role, setRole] = useState<Role>(initialRole);
  const [updating, setUpdating] = useState(false);
  const showDialog = useConfirmDialog();
  const handleSave = useCallback(async () => {
    setUpdating(true);
    try {
      await onUpdate(id, role);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  }, [onUpdate, id, role]);
  const handleDelete = useCallback(() => {
    showDialog({
      title: `メンバーを削除しますか？`,
      description: (
        <>
          "{name}" を削除しますか？
          <br />
          一度削除すると元に戻せません
        </>
      ),
      okText: '削除',
      okType: 'danger',
      onOk: () => onDelete(id),
    });
  }, [showDialog, name, onDelete, id]);
  return (
    <SettingPageDrawer
      title="メンバーを編集"
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      <div className="flex flex-col gap-4">
        <InputGroup label="メールアドレス">
          <Input defaultValue={email} readOnly disabled={updating} />
        </InputGroup>
        <InputGroup label="役割">
          <RoleSelect
            value={role}
            onChange={setRole}
            disabled={readonly || updating}
          />
        </InputGroup>
        <div className="flex gap-4">
          <Button
            onClick={handleSave}
            disabled={readonly}
            loading={updating}
            className="min-w-[72px]"
          >
            保存
          </Button>
          <Button
            onClick={handleDelete}
            color="danger"
            variant="outlined"
            disabled={readonly || updating}
          >
            削除
          </Button>
        </div>
      </div>
    </SettingPageDrawer>
  );
};
